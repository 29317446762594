.api-item-endpoint {
  display: flex;
  flex-flow: row nowrap;
  background-color: #e9e7ed85;
  flex-basis: 100%;
  padding: 7px 12px;
}

.api-item__api-item-details {
  display: none;
  flex-flow: column nowrap;
}

.api-item-details--active {
  display: flex;
  color: #ff9c39 !important;
}

.api-item-details--active span {
  color: #ff9c39 !important;
}

.api-item-endpoint > span {
  display: block;
  font-size: 0.8em;
  margin: 3px 0px 0px 20px;
  color: #4d4d4d8f;
  word-spacing: 5px !important;
  font-family: 'Montserrat Medium';
  align-self: center;
}

.api-item-endpoint > span:nth-child(1) {
  background-color: #fff;
  padding: 3px 7px;
  letter-spacing: 0.7px;
  font-size: 0.75em;
  font-family: 'Montserrat Bold';
  border: 1px solid #ff9c39;
}

.api-item-endpoint > span:nth-child(2) {
  font-size: 0.7em;
  letter-spacing: 0.5px;
  margin-left: 15px;
  color: #707070 !important;
  font-family: 'Poppins Regular';
  text-decoration: underline;
  line-height: 1em;
}
