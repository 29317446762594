.map-menu-ext {
  position: absolute;
  top: 59px;
  right: 25px;
  background-color: #fff;
  display: flex;
  flex-flow: column nowrap;
  border: 1px solid #4d4d4d1c;
  justify-content: space-between;
  width: 18vw;
  border-radius: 13px;
  padding: 0px 0px 0px 0px;
  box-shadow: -1px 2px 7px 2px rgba(180, 177, 177, 0.549);
  z-index: 300000;
}

.map-menu-ext .map-menu-ext__item {
  display: flex;
  flex-flow: row nowrap;
  font-family: 'Poppins Regular';
  color: #707070;
  padding: 15px 10px;
  font-size: 0.8em;
  text-align: center;
}

.map-menu-ext .map-menu-ext__item:nth-child(1) {
  border-top-left-radius: 13px;
  border-top-right-radius: 13px;
}
.map-menu-ext .map-menu-ext__item:nth-child(5) {
  border-top: 1px solid #7070702d;
  padding-top: 15px;
  color: #ff0000;
}
.map-menu-ext .map-menu-ext__item:hover {
  background-color: #70707011;
  cursor: pointer;
}
.map-menu-ext img {
  width: 1.8em;
  height: 1.5em;
  margin-right: 15px;
  margin-left: 10px;
  opacity: 1;
}
