/* Filters Menu Styles */
.filter-menu {
  border-top: 1px solid #4d4d4d46;
  border-bottom: 1px solid #4d4d4d46;
  margin-top: 50px;
  position: relative;
}

.filter-menu__heading {
  color: #707070b4;
  font-size: 0.9em;
  background-color: white;
  letter-spacing: 0.7px;
  position: absolute;
  top: -29px;
  left: 40px;
  max-width: max-content;
  padding: 4px 15px;
  vertical-align: baseline;
}

/* Contains the filter-controls - displays them in a row.  */

.filter-row {
  display: flex;
  flex-flow: row wrap;
  justify-content: flex-start;
  justify-items: center;
  margin: 10px 0% 55px 70px;
}

/* Filter Submission Row */
.filter-submit-items {
  display: flex;
  flex-flow: row nowrap;
  margin-left: 70px;
  margin-bottom: 30px;
}

.filter-submit-items button {
  margin-right: 5px;
  padding: 7px 15px;
  background-color: #016f99;
  border: none;
  color: #fff;
  border-radius: 4px;
  font-family: 'Poppins Light';
  letter-spacing: 0.3px;
  font-size: 0.85em;
}

.filter-submit-items button:nth-child(2) {
  background: transparent;
  color: #016f99;
  border-radius: 0px;
  padding: 4px 10px;
}

.filter-submit-items button:hover {
  cursor: pointer;
}
