/* map container zoom control styles */
.map-container__zoom-controls {
  grid-row: 1 / 3;
  grid-column: 1 / 2;
}

.zoom-controls {
  display: flex;
  flex-flow: column nowrap;
  background-color: rgb(58, 57, 57);
  width: max-content;
  margin: 8px 10px;
  height: max-content;
  padding: 7px 4px;
  border-radius: 6px;
  z-index: 10000;
}

.zoom-controls__control {
  border: none;
  background-color: transparent;
  width: max-content;
  height: max-content;
  cursor: pointer;
}

.zoom-controls__control:nth-child(1) {
  border-bottom: 1px solid grey;
  padding-bottom: 2px;
}

.zoom-controls__control img {
  width: 0.95em;
  padding: 2px 0px;
  height: 0.95em;
}

.zoom-controls__control:nth-child(2) img {
  margin-top: 3px;
}
