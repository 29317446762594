/* Dashboard SEARCH Section   */
.application-container__search-section {
  grid-row: 2 / 10;
  grid-column: 1 / 4;
}

/* 
      application-container Gird Distribution -
      Rows - 10 - space-left: 88vh  
        - Row Size: 8.8vh
      Columns - 3 
        - 1st Column - 20vw
        - 2nd Column - 60vw
        - 3rd Column - 20vw
    
    
    */

.dashboard-search {
  display: grid;
  grid-template-rows: repeat(10, 8.8vh);
  grid-template-columns: 20vw 60vw 20vw;
  background: linear-gradient(
        180deg,
        rgb(90 89 89 / 8%) 0%,
        rgba(119, 113, 113, 0.014) 10%,
        rgb(151 148 148 / 9%) 40%,
        rgb(161 161 161 / 12%) 100vh
      )
      0% 0% no-repeat border-box,
    url(../../assets/images/search-dashboard-bg.png) -15vw 0px / 130vw 95vh no-repeat;
}

.dashboard-search__heading {
  grid-row: 4 / 5;
  grid-column: 2 / 3;
  font-family: 'Montserrat Regular', sans-serif;
  letter-spacing: 0.2px;
  text-align: center;
  font-size: 1.9em;
  margin-top: -2px;
  margin-left: -35px;
  color: #4d4d4dfb;
  text-transform: uppercase;
}

.search-heading__img {
  width: 1em;
  height: 1em;
  padding-right: 20px;
  margin-bottom: -5px;
}
