/*
      ZONING CHANGES BLOCK - 
    
      Styles for the zoning changes section that contains the zoning changes heading, info content and the table.
      
    */

/* sets zoning-changes element style wrt to main-application block layout definition */
.application-container__zoning-changes {
  grid-row: 2 / 11;
  grid-column: 2 / 3;
}

/* BLOCK - zoning-changes - sets styles for zoning-changes block   */
.zoning-changes {
  margin: 40px 0px 20px 0px;
  border: 1px solid #70707048;
  box-shadow: 0px 10px 20px 0px rgba(116, 112, 112, 0.226);
  border-radius: 6px;
  padding: 10px 40px 10px 40px;
}

/* Element : zoning-changes-header - sets header defintion wrt to zoning-changes block  */
.zoning-changes__header {
  font-family: 'Montserrat Bold';
  text-transform: uppercase;
  font-size: 2.2em;
  color: #8c8c8c;
  /* color: #707070b0; */
}

/* Element : zoning-changes__info-text - sets the styles for info-text element*/
.info-text {
  font-family: 'Poppins Light';
  letter-spacing: 1px;
  font-size: 0.8em;
  color: #4284fd;
  background-color: #d9edf7;
  padding: 10px 5px;
  border-left: 6px solid #5799ba;
  margin-block-end: 0px;
  margin-block-start: 0px;
  line-height: 1.6em;
}

/* Element : zoning-changes-info__img - sets styles for the icons inside the zoning-changes-info block */
.info-text img {
  width: 1.3em;
  height: 1.3em;
  margin-right: 7px;
  position: relative;
  opacity: 0.7;

  top: 3px;
  margin-left: 10px;
}

/* Block - controls-row - sets controls for the data filter i-e filter and pagination */
.controls-row {
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  margin: 30px 10px;
}

/* Element - contorls-row__filter */
.controls-row__filter {
  background-color: #ff9c39;
  font-family: 'Poppins Regular';
  padding: 8px 14px;
  display: flex;
  flex-flow: row nowrap;
  justify-items: center;
  align-items: center;
  font-size: 1.1em;
  color: #fff;
  border-radius: 0.35em;
  box-shadow: 0px 5px 10px 0px #8079795b;
  cursor: pointer;
}

.controls-row__filter span {
  text-align: center;
}

/* filter icon image - setting dimensions and alignment */
.controls-row__filter img {
  width: 1.3em;
  height: 1.2em;
  position: relative;
  top: 0px;
  margin-left: 3px;
  color: white;
}
