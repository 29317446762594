/* Sigin container Block - sets main page layout - Grid   */
.signin-container {
  display: grid;
  grid-template-columns: 50vw 50vw;
  grid-template-rows: 3vh 17vh 10vh 10vh 10vh 10vh 10vh 10vh 17vh 3vh;
  overflow-x: hidden;
  height: 100vh;
  overflow-y: hidden;
  width: 100%;
}

/* background image container - Element : sets up the size of the image on the right wrt to signin-container */
.signin-container__background-image-container {
  grid-row: 1 / 11;
  grid-column: 2 / 3;
}

/* Sets up the size for the background image on the right to 50vw */
.signin-container__background-image-container img {
  width: 50vw;
  height: 100vh;
}
