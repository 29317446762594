.under-construction {
  display: grid;
  grid-template-columns: 49vw 49vw;
  grid-template-rows: 100vh;
}

.under-construction__col-1 {
  grid-row: 1 / 2;
  grid-column: 1 / 2;
  margin: 90px 60px;
}

.under-construnction__col-2 {
  grid-row: 1 / 2;
  grid-column: 2 / 3;
}

.under-construction-text {
  display: flex;
  flex-flow: column nowrap;
  justify-items: center;
  align-items: center;
  height: 100vh;
  justify-content: center;
}

.under-construction-text__heading {
  font-family: 'Montserrat Regular';
  font-size: 1.75em;
  color: #707070;
  margin-block-end: 0px;
  margin-block-start: 0px;
}

.under-construction-text__sub-heading {
  font-family: 'Montserrat Light';
  font-size: 1.1em;
  color: rgba(112, 112, 112, 0.9);
  text-align: center;
  line-height: 2.5em;
}
.under-construction svg {
  width: 100%;
  height: 100%;
}

.under-construction
  svg#freepik_stories-under-construction:not(.animated)
  .animable {
  opacity: 0;
}
.under-construction
  svg#freepik_stories-under-construction.animated
  #freepik--Window--inject-9 {
  animation: 1s 1 forwards cubic-bezier(0.36, -0.01, 0.5, 1.38) slideLeft;
  animation-delay: 0s;
}
.under-construction
  svg#freepik_stories-under-construction.animated
  #freepik--Crane--inject-9 {
  animation: 1s 1 forwards cubic-bezier(0.36, -0.01, 0.5, 1.38) slideRight;
  animation-delay: 0s;
}
.under-construction
  svg#freepik_stories-under-construction.animated
  #freepik--Character--inject-9 {
  animation: 1.5s Infinite linear floating;
  animation-delay: 0s;
}
@keyframes slideLeft {
  0% {
    opacity: 0;
    transform: translateX(-30px);
  }
  100% {
    opacity: 1;
    transform: translateX(0);
  }
}
@keyframes slideRight {
  0% {
    opacity: 0;
    transform: translateX(30px);
  }
  100% {
    opacity: 1;
    transform: translateX(0);
  }
}
@keyframes floating {
  0% {
    opacity: 1;
    transform: translateY(0px);
  }
  50% {
    transform: translateY(-10px);
  }
  100% {
    opacity: 1;
    transform: translateY(0px);
  }
}
