table.development-table tbody td {
  padding: 18px 10px;
}

table.development-table tbody td:nth-child(1),
table.development-table thead th:nth-child(1) {
  width: 30%;
  /* font-weight: 700; */
}

table.development-table tbody td:nth-child(2),
table.development-table thead th:nth-child(2) {
  width: 15%;
}

table.development-table tbody td:nth-child(3),
table.development-table thead th:nth-child(3) {
  width: 20%;
}

table.development-table tbody td:nth-child(4),
table.development-table thead th:nth-child(4) {
  width: 15%;
}

table.development-table tbody td:nth-child(5),
table.development-table thead th:nth-child(5) {
  width: 20%;
}

table.development-table tbody tr:hover {
  background-color: #ebebeb59;
  cursor: pointer;
}
