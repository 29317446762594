.map-search-form__suggestions {
  position: absolute;
  list-style-type: none;
  background-color: #fff;
  top: 99%;
  max-width: 70.5%;
  min-width: 70.5%;
  left: 0px;
  border-radius: 0px 0px 12px 12px;
  display: none;
  font-family: 'Montserrat Regular';
  opacity: 1;
  z-index: 20000;
  border-right: 1px solid rgba(112, 112, 112, 0.493);
  border-left: 1px solid rgba(112, 112, 112, 0.493);
  border-bottom: 1px solid rgba(112, 112, 112, 0.493);
  font-size: 1.1em;
  box-shadow: 0px 15px 5px 0px rgba(112, 112, 112, 0.226);
}

.map-search-form__suggestions .suggestions__wrapper {
  border-top: 1px solid rgba(112, 112, 112, 0.336);
  padding: 0px 3px;
  overflow-y: hidden;
}

.map-search-form__suggestions .suggestions__wrapper:hover {
  overflow-y: scroll;
}

.map-search-form__suggestions img {
  width: 7%;
  height: 6.5%;
  padding-left: 10px;
}
.map-search-form__input:focus-visible {
  outline-color: transparent;
  outline-width: 0px;
}

.map-search-form__input--tray-active {
  border-radius: 18px 0% 0% 0px !important;
  border-bottom: none;
  z-index: 1000;
}

.legend__g-icon {
  display: flex;
  flex-flow: row nowrap;
  padding: 7px 5px;
  border-top: 1px solid #4d4d4d1e;
  box-sizing: border-box;
}

.legend__g-icon img {
  justify-self: center;
  display: block;
  width: 35%;
  height: 1em;
  margin-left: 60%;
  margin-bottom: 5px;
  margin-top: 10px;
}
