/* BLOCK - data-table - sets the styles for the data-table block   */
table.data-table {
  font-family: 'Poppins Regular';
  font-size: 0.85em;
  line-height: 2em;
  text-align: center;
  color: #4d4d4d;
  margin-top: 70px;
  width: 100%;
}

table.data-table thead tr {
  border-collapse: collapse;
  border: none;
}

table.data-table thead th {
  /* border-collapse: collapse; */
  border: none;
  background-color: #f5f6fa;
  padding: 10px 0px;
  font-family: 'Poppins SemiBold';
  font-size: 1em;
  text-transform: uppercase;
}

table.data-table tbody td {
  padding: 18px 10px;
}

table.data-table tbody td,
table.data-table thead th {
  width: 20%;
  text-transform: capitalize;
  /* font-weight: 700; */
}

table.data-table tbody td a {
  text-decoration: underline;
  color: #2443da;
  cursor: pointer;
}
