/* OFFMARKET FILTER: FILTER TRAY STYLES */

.offmarket-filter {
  position: relative;
}

.offmarket-filter__offmarket-tray {
  display: block;
  width: 33vw;
  position: absolute;
  top: 60px;
  right: 3px;
  background-color: #fff;
  z-index: 100000;
  color: #4d4d4d;
  border-radius: 9px;
  border: 1px solid rgba(112, 112, 112, 0.315);
  opacity: 1;
}

.offmarket-tray__wrapper {
  display: flex;
  flex-flow: column nowrap !important;
  overflow-y: scroll;
  height: 60.5vh;
}

/* OFF MARKET FILTER: SCROLL BAR STYLES  */

.offmarket-tray__wrapper::-webkit-scrollbar {
  width: 6px;
  background-color: rgba(117, 115, 115, 0);
  padding: 5px;
  overflow: hidden;
}

.offmarket-tray__wrapper::-webkit-scrollbar-thumb {
  border-radius: 16px;
  padding: 5px;
  -webkit-box-shadow: inset 0 0 6px rgba(147, 147, 147, 0);
  box-shadow: inset 0 0 6px rgba(147, 147, 147, 0.2);
  background-color: rgba(147, 147, 147, 0.7);
}

.offmarket-tray__wrapper::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(70, 70, 70, 0.3);
  border-radius: 16px;
  padding: 10px;
  margin: 10px;
  background-color: rgba(255, 255, 255, 0.85);
}

.offmarket-tray {
  display: flex;
  flex-flow: column nowrap;
  align-items: stretch;
  padding: 10px 15px;
}

/* OFF MARKET FILTER: HEADING ROW STYLES */

.heading-row {
  border-bottom: 1px solid rgba(112, 112, 112, 0.3);
  margin-top: -7px;
  display: flex;
  flex-flow: row nowrap;
}

.heading-row svg {
  display: inline;
  margin-right: 10px !important;
  max-width: 2em !important;
  max-height: 2em !important;
}

.heading-row__heading {
  font-family: 'Poppins Light';
  letter-spacing: 0.5px;
  text-transform: uppercase;
  color: rgba(117, 115, 115, 0.774);
  font-size: 1.25em;
  flex-grow: 2;
}

.heading-row span {
  flex-grow: 2;
  display: block;
  align-self: flex-end;
  justify-self: flex-end;
  font-size: 0.85em;
  text-align: right;
  color: #25b7d3;
  font-family: 'Montserrat Medium';
  margin-bottom: 10px;
  position: relative;
}

/* OFF MARKET FILTER: INFO SECTION STYLES */

.offmarket-filter__info-text {
  display: flex;
  flex-flow: row nowrap;
  margin: 10px 10px 10px 0px;
  font-size: 0.72em;
  font-family: 'Poppins Light';
  line-height: 2em;
}

.offmarket-filter__info-text span:nth-child(1) {
  flex-basis: 6%;
}

.offmarket-filter__info-text span:nth-child(1) img {
  width: 1.25em;
  height: 1.25em;
  margin-left: 5px;
}

.offmarket-filter__info-text span:nth-child(2) {
  flex-basis: 94%;
}

.offmarket-tray__filter-submit-items {
  display: flex;
  flex-flow: row nowrap;
  margin: 30px 15px !important;
  padding: 0px 0px !important;
}
