/* sets zoning-changes element style wrt to main-application block layout definition */
.admin-application-container__admin-dashboard {
  grid-row: 2 / 11;
  grid-column: 2 / 3;
}

/* BLOCK - zoning-changes - sets styles for zoning-changes block   */
.admin-dashboard {
  margin: 40px 0px 20px 0px;
  border: 1px solid #70707048;
  box-shadow: 0px 10px 20px 0px rgba(116, 112, 112, 0.226);
  border-radius: 6px;
  padding: 10px 40px 10px 40px;
}

/* Element : zoning-changes-header - sets header defintion wrt to zoning-changes block  */
.admin-dashboard__header {
  font-family: 'Montserrat Bold';
  text-transform: uppercase;
  font-size: 2em;
  line-height: 2em;
  color: #8c8c8c;
  /* color: #707070b0; */
}

/* Block - controls-row - sets controls for the data filter i-e filter and pagination */
.controls-row {
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  margin: 30px 0px;
}

.admin-filter-row {
  display: flex;
  flex-flow: column nowrap;
  margin: 0px 0px;
  flex-basis: 50% !important;
}

.admin-filter-row input {
  font-family: 'Poppins Regular';
  line-height: 1.5em;
  padding: 12px 20px;
  margin-right: 5px;
  color: #707070;
  font-size: 0.9em;
  flex-basis: 40%;
  max-width: 30vw;
  outline-color: #fff;
  outline-width: 0px;
  border: 1px solid #70707062;
  border-radius: 8px;
}
/* Element - contorls-row__filter */

.controls-row .admin-controls-row__filter {
  background-color: #1c63e7e5;
  font-family: 'Poppins Regular';
  padding: 10px 14px;
  display: flex;
  flex-flow: row nowrap;
  border: none;
  justify-items: center;
  align-items: center;
  font-size: 0.9em;
  width: max-content;
  margin: 10px 5px;
  color: #fff;
  border-radius: 0.35em;
  box-shadow: 0px 5px 10px 0px #8079795b;
  cursor: pointer;
}

.admin-controls-row__filter span {
  text-align: center;
}

/* filter icon image - setting dimensions and alignment */
.admin-controls-row__filter img {
  width: 1.5em;
  height: 1.3em;
  position: relative;
  top: 0px;
  margin-left: 10px;
  color: white;
}

/* Block Element - Sets Controls Pagination block styles */
.controls-pagination {
  display: flex;
  flex-flow: row nowrap;
  max-height: 2.5em;
}

/* Controls Pagination  -  Sets up control pagination buttons */
.controls-pagination button {
  background-color: #fff;
  border: none;
  width: max-content;
  padding: 0px 12px;
  color: #7e84a3;
  font-size: 0.8em;
  cursor: pointer;
}

/* Control Pagination - Sets the active button behaviour  */
button.control-pagination--active {
  background-color: #0058ff;
  color: #fff;
  border-radius: 3px;
}

/* Controls Pagination - Interaction styles for the first and last - arrow button */
.controls-pagination button:nth-child(1):hover,
.controls-pagination button:nth-last-child(1):hover {
  background-color: #fff;
  background: radial-gradient(
    circle,
    rgba(223, 206, 206, 0.25) 50%,
    rgba(255, 255, 255, 0) 80%
  );
}

/* Pagination Styles - Sets Alignment Styles for the first and last items  */
.controls-pagination button:nth-child(1),
.controls-pagination button:nth-last-child(1) {
  padding: 0px 5px;
}

/* BLOCK - zoning-table - sets the styles for the zoning-table block   */
table.admin-dashboard-table {
  font-family: 'Poppins Regular';
  font-size: 0.85em;
  line-height: 2em;
  text-align: center;
  color: #4d4d4d;
  margin-top: 70px;
  width: 100%;
}

table.admin-dashboard-table thead tr {
  border-collapse: collapse;
  border: none;
}

table.admin-dashboard-table thead th {
  border-collapse: collapse;
  border: none;
  background-color: #f5f6fa;
  padding: 10px 0px;
  font-family: 'Poppins SemiBold';
  font-size: 0.9em;
  text-transform: uppercase;
}

table.admin-dashboard-table tbody td {
  padding: 15px 10px;
}

table.admin-dashboard-table tbody td:nth-child(1) {
  width: 10%;
  min-width: 10%;
  /* font-weight: 700; */
}

table.admin-dashboard-table tbody td:nth-child(2) {
  width: 20%;
  min-width: 20%;
}

table.admin-dashboard-table tbody td:nth-child(3) {
  width: 20%;
  min-width: 20%;
  color: #4d4d4d;
  font-family: 'Poppins Medium';
}

table.admin-dashboard-table tbody td:nth-child(4) {
  width: 15%;
  min-width: 15%;
  text-align: center;
}

table.admin-dashboard-table tbody td:nth-child(5) {
  width: 15%;
  min-width: 15%;
  text-align: center;
  text-transform: capitalize;
}

table.admin-dashboard-table tbody td:nth-child(6) {
  width: 20%;
  min-width: 20%;
  text-align: start;
}

table.admin-dashboard-table tbody td:nth-child(6) .user-control {
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
}

table.admin-dashboard-table tbody td:nth-child(6) .user-control button {
  border: none;
  background-color: transparent;
}

table.admin-dashboard-table
  tbody
  td:nth-child(6)
  .user-control
  button:nth-child(1) {
  margin-right: 15px;
}

table.admin-dashboard-table tbody td:nth-child(6) .user-control button img {
  width: 1.7em;
  height: 1.5em;
  cursor: pointer;
}

table.admin-dashboard-table
  tbody
  td:nth-child(6)
  .user-control
  button:hover
  img {
  transform: translateY(-2px);
}
