/* Map Page Styles -
    Contains Styles for Map Menu and Map Container 
 
*/

/* map page styles wrt to the main application-container grid */
.application-container__map-page {
  grid-row: 2 / 10;
  grid-column: 1 / 4;
}

/* 
      map-page Gird Distribution - Space Left 88vh
      Rows - 2  
        - map-menu: 16 vh
        - map-container: 72vh
      Columns - 10 
        - 10 Columns: 10 vw
    
    */

/* map-page - layout definition */
.map-page {
  display: grid;
  grid-template-rows: 16vh repeat(8, 9vh);
  grid-template-columns: repeat(10, 10vw);
}

/* 
      map-container  Gird Distribution - Space Left 72
      Rows - 10 - 7.2vh   
        - 
      Columns - 10 
        - 10 Columns: 10 vw
    
    */

.map-container {
  display: grid;
  grid-template-rows: repeat(10, 7.2vh);
  grid-template-columns: repeat(10, 10vw);
}

.leaflet-map {
  grid-row: 1 / 11;
  grid-column: 1 / 11;
  width: 100%;
  height: 100%;
  overflow-x: hidden;
  overflow-y: hidden;
}

.leaflet-map--hover {
  cursor: pointer;
}

.toggle-active {
  width: 99%;
}
