.filter-control {
  display: flex;
  flex-flow: column nowrap;
  flex-basis: 18vw;
  justify-content: stretch;
  align-items: stretch;
  margin-right: 8%;
  margin-top: 50px;
  position: relative;
}

.filter-control__heading {
  margin-block-start: 0px;
  margin-block-end: 0px;
  margin: 0px 0px 10px 5px;
  font-family: 'Poppins Light';
  font-size: 0.8em;
  text-transform: uppercase;
  color: #707070c4;
}

/* Custom Styles for the date range filters */
.filter-control input[type='date'] {
  flex-basis: 22.5%;
  border: 1px solid rgba(112, 112, 112, 0.32);
  font-family: 'Poppins Light';
  padding: 11px 15px;
  border-radius: 4px;
  color: #4d4d4de0;
  text-transform: uppercase;
  font-size: 0.875em;
  letter-spacing: 0.8px;
}

.filter-control input[type='date']:hover {
  background-color: rgba(112, 112, 112, 0.096);
  cursor: pointer;
}

.filter-control input[type='date']:focus-visible {
  outline-color: transparent;
  outline-width: 0px;
}

.control-item__input {
  font-family: 'Poppins Light';
  color: rgb(112, 112, 112, 0.9) !important;
  font-weight: 400;
  font-size: 0.9em;
  padding: 13px 20px;
  border: 1px solid rgba(112, 112, 112, 0.3);
  border-radius: 6px;
  /* margin-bottom: 10px; */
  line-height: 1.3em;
}

.control-item__input:focus-visible {
  outline-color: transparent;
  outline-width: 0px;
}

.filter-row .control-item__input {
  margin-bottom: 0px !important;
}

.zoning-changes .filter-row > div.filter-control:nth-child(1) {
  flex-basis: 54% !important;
}

.range-filter {
  display: flex;
  flex-flow: row nowrap;
  /* margin: 10px 0px; */
}

.range-filter > div::after {
  position: absolute;
  font-family: 'Montserrat Light';
  text-transform: uppercase;
  font-size: 0.85em;
  font-weight: 700;
  color: #707070;
  top: 10px;
  left: 12px;
}

.range-filter > div {
  flex-basis: 5em;
  height: 2px;
  align-self: center;
  margin: 10px 10px;
  background-color: rgba(112, 112, 112, 0.5);
  position: relative;
}

.range-filter > input {
  width: 5em;
  font-size: 0.85em;
  padding: 12px 15px;
  border: 1px solid rgba(112, 112, 112, 0.3);
  border-radius: 3px;
  color: #4d4d4dc0;
  font-family: 'Poppins Regular';
}

.range-filter > input:focus-visible {
  outline-color: transparent;
  outline-width: 0px;
}
