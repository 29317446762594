/* MAIN HEADER STYLES */

.header {
  grid-row-start: header-start;
  grid-row-end: header-end;
  grid-column: 1/11;
  display: flex;
  justify-content: center;
  flex-flow: row nowrap;
  padding: 35px 20px 15px;
}

.header__logo {
  flex-basis: 50vw;
  margin-left: 30px;
}

.logo__img {
  width: 14em;
  opacity: 1;
}
