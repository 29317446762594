/* API CONTENT STYLES */
.api-container__api-content {
  flex-basis: 80%;
  margin: 30px 5px 10px 40px;
  overflow-y: scroll;
}

.api-container__api-content::-webkit-scrollbar {
  width: 6px;
  background-color: rgba(117, 115, 115, 0.73);
  margin: 2px 0px;
}

.api-container__api-content::-webkit-scrollbar-thumb {
  border-radius: 5em;
  -webkit-box-shadow: inset 0 0 6px rgba(147, 147, 147, 0.2);
  box-shadow: inset 0 0 6px rgba(147, 147, 147, 0.2);
  background-color: rgba(147, 147, 147, 0.7);
  margin: 10px 0px;
}

.api-container__api-content::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(70, 70, 70, 0.1);
  border-radius: 5em;
  background-color: rgba(255, 255, 255, 0.85);
  margin: -1px 0px;
}

.request-heading {
  display: flex;
  flex-flow: row nowrap;
}

.request-heading > span:nth-child(1) {
  display: inline-block;
  border: 1px solid #ff9c39;
  margin-right: 10px;
  padding: 4px 10px;
  font-size: 0.85em;
  background-color: #fff;
  color: #ff9c39;
  font-family: 'Montserrat SemiBold';
  letter-spacing: 1px;
}

.request-heading > span:nth-child(2) {
  font-size: 0.75em;
  color: #8c8c8c;
  border-bottom: 1px solid #8c8c8c65;
  padding: 3px 0px;
  margin-left: 10px;
  width: max-content;
  font-family: 'Poppins Regular';
  letter-spacing: 1px;
}

p.api-content__request-description {
  font-size: 0.82em;
  margin: 25px 0px 0px 0px;
  color: #8c8c8c;
  padding: 10px 30px 10px 5px;
  line-height: 2.2em;
  letter-spacing: 0.5px;
}

p.api-content__request-description .api-credentials__link {
  color: #3377ff;
  border-bottom: 1px solid #3377ff;
}

.api-content__code {
  max-width: 75vw;
  overflow-x: hidden;
}

.api-content__code pre {
  padding: 10px 15px;
}
