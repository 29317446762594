.leaflet-control-measure h3,
.leaflet-measure-resultpopup h3 {
  margin: 0 0 12px;
  padding-bottom: 10px;
  border-bottom: 1px solid #ddd;
}
.leaflet-control-measure p,
.leaflet-measure-resultpopup p {
  margin: 10px 0 0;
  line-height: 1.5em;
}
.leaflet-control-measure p:first-child,
.leaflet-measure-resultpopup p:first-child {
  margin-top: 0;
}
.leaflet-control-measure .tasks,
.leaflet-measure-resultpopup .tasks {
  margin: 12px 0 0;
  padding: 10px 0 0;
  border-top: 1px solid #ddd;
  text-align: right;
  list-style: none;
  list-style-image: none;
}
.leaflet-control-measure .tasks li,
.leaflet-measure-resultpopup .tasks li {
  display: inline;
  margin: 0 10px 0 0;
}
.leaflet-control-measure .tasks li:last-child,
.leaflet-measure-resultpopup .tasks li:last-child {
  margin-right: 0;
}
.leaflet-control-measure .coorddivider,
.leaflet-measure-resultpopup .coorddivider {
  color: #999;
}
.leaflet-control-measure {
  max-width: 280px !important;
  color: #e7e6e6;
  padding: 3px 7px !important;
  position: absolute;
  top: 2px;
  left: 4px;
}
.leaflet-control-measure .leaflet-control-measure-toggle,
.leaflet-control-measure .leaflet-control-measure-toggle:hover {
  background-size: 1.2em 1.2em;
  background-image: url('../../assets/images/ruler.svg');
  border: 0;
  border-radius: 4px;
  text-indent: 100%;
  white-space: nowrap;
  overflow: hidden;
}
.leaflet-touch .leaflet-control-measure .leaflet-control-measure-toggle,
.leaflet-touch .leaflet-control-measure .leaflet-control-measure-toggle:hover {
  border-radius: 2px;
}
.leaflet-retina .leaflet-control-measure .leaflet-control-measure-toggle,
.leaflet-retina .leaflet-control-measure .leaflet-control-measure-toggle:hover {
  background-image: url('../../assets/images/ruler.svg');
}
.leaflet-touch .leaflet-control-measure .leaflet-control-measure-toggle,
.leaflet-touch .leaflet-control-measure .leaflet-control-measure-toggle:hover {
  background-color: transparent;
  background-size: 1.8em 1.8em;
  border: none;
}
.leaflet-control-measure .startprompt h3 {
  margin-bottom: 10px;
}
.leaflet-control-measure .startprompt .tasks {
  margin-top: 0;
  padding-top: 0;
  border-top: 0;
  text-align: left;
}
.leaflet-control-measure .leaflet-control-measure-interaction {
  padding: 10px 12px;
}
.leaflet-control-measure .results .group {
  margin-top: 10px;
  padding-top: 10px;
  border-top: 1px dotted #eaeaea;
}
.leaflet-control-measure .results .group:first-child {
  margin-top: 0;
  padding-top: 0;
  border-top: 0;
}
.leaflet-control-measure .results .heading {
  margin-right: 5px;
  color: #999;
}
.leaflet-control-measure a.start {
  display: inline;
  width: auto;
  height: auto;
  padding-left: 20px;
  margin-right: 4px;
  line-height: 1em;
  border: 0;
  text-align: left;
  background-image: url('../../../node_modules/leaflet-measure/assets/start.png');
  background-repeat: no-repeat;
  background-position: 0 50%;
  background-size: 12px 12px;
  color: #5e66cc;
  text-decoration: none;
}
.leaflet-control-measure a.start,
.leaflet-control-measure a.start:hover {
  background-color: transparent;
}
.leaflet-retina .leaflet-control-measure a.start {
  background-image: url('../../../node_modules/leaflet-measure/assets/start_@2X.png');
}
.leaflet-control-measure a.start:hover {
  opacity: 0.5;
  text-decoration: none;
}
.leaflet-control-measure a.cancel {
  display: inline;
  width: auto;
  height: auto;
  padding-left: 20px;
  margin-right: 4px;
  line-height: 1em;
  border: 0;
  text-align: left;
  background-image: url('../../../node_modules/leaflet-measure/assets/cancel.png');
  background-repeat: no-repeat;
  background-position: 0 50%;
  background-size: 12px 12px;
  color: #5e66cc;
  text-decoration: none;
}
.leaflet-control-measure a.cancel,
.leaflet-control-measure a.cancel:hover {
  background-color: transparent;
}
.leaflet-retina .leaflet-control-measure a.cancel {
  background-image: url('../../../node_modules/leaflet-measure/assets/cancel_@2X.png');
}
.leaflet-control-measure a.cancel:hover {
  opacity: 0.5;
  text-decoration: none;
}
.leaflet-control-measure a.finish {
  display: inline;
  width: auto;
  height: auto;
  padding-left: 20px;
  margin-right: 4px;
  line-height: 1em;
  border: 0;
  text-align: left;
  background-image: url('../../../node_modules/leaflet-measure/assets/check.png');
  background-repeat: no-repeat;
  background-position: 0 50%;
  background-size: 12px 12px;
  color: #5e66cc;
  text-decoration: none;
}
.leaflet-control-measure a.finish,
.leaflet-control-measure a.finish:hover {
  background-color: transparent;
}
.leaflet-retina .leaflet-control-measure a.finish {
  background-image: url('../../../node_modules/leaflet-measure/assets/check_@2X.png');
}
.leaflet-control-measure a.finish:hover {
  opacity: 0.5;
  text-decoration: none;
}
.leaflet-measure-resultpopup a.zoomto {
  display: inline;
  width: auto;
  height: auto;
  padding-left: 20px;
  margin-right: 4px;
  line-height: 1em;
  border: 0;
  text-align: left;
  background-image: url('../../../node_modules/leaflet-measure/assets/focus.png');
  background-repeat: no-repeat;
  background-position: 0 50%;
  background-size: 12px 12px;
  color: #5e66cc;
  text-decoration: none;
}
.leaflet-measure-resultpopup a.zoomto,
.leaflet-measure-resultpopup a.zoomto:hover {
  background-color: transparent;
}
.leaflet-retina .leaflet-measure-resultpopup a.zoomto {
  background-image: url(../../../node_modules/leaflet-measure/assets/focus_@2X.png);
}
.leaflet-measure-resultpopup a.zoomto:hover {
  opacity: 0.5;
  text-decoration: none;
}
.leaflet-measure-resultpopup a.deletemarkup {
  display: inline;
  width: auto;
  height: auto;
  padding-left: 20px;
  margin-right: 4px;
  line-height: 1em;
  border: 0;
  text-align: left;
  background-image: url('../../../node_modules/leaflet-measure/assets/trash.png');
  background-repeat: no-repeat;
  background-position: 0 50%;
  background-size: 12px 12px;
  color: #5e66cc;
  text-decoration: none;
}
.leaflet-measure-resultpopup a.deletemarkup,
.leaflet-measure-resultpopup a.deletemarkup:hover {
  background-color: transparent;
}
.leaflet-retina .leaflet-measure-resultpopup a.deletemarkup {
  background-image: url('../../../node_modules/leaflet-measure/assets/trash_@2X.png');
}
.leaflet-measure-resultpopup a.deletemarkup:hover {
  opacity: 0.5;
  text-decoration: none;
}
