/* map-menu: search-form element - sets styles for the search element  */
.map-menu__map-search-form {
  flex-grow: 8;
  display: flex;
  flex-flow: row nowrap;
  margin-right: 10px;
  position: relative;
}

/* map-menu: search-form input element */
.map-search-form__input {
  flex-grow: 3;
  padding: 12.5px 30px;
  font-size: 1em;
  border: none;
  opacity: 0.9;
  border-radius: 13px 0% 0% 13px;
  color: #5a5858be;
  border-top: 1px solid rgba(112, 112, 112, 0.41);
  border-left: 1px solid rgba(112, 112, 112, 0.41);
  border-bottom: 1px solid rgba(112, 112, 112, 0.41);
  cursor: text;
}

/* map-menu: search-form input - focus modifier */
.map-search-form__input:focus-visible {
  outline-color: transparent;
  outline-width: 0px;
}

/* map-menu: search-form submit button */
.map-search-form__submit-btn {
  flex-grow: 1.4;
  border: none;
  padding: 0px 10px;
  border-radius: 0% 13px 13px 0%;
  font-size: 20px;
  font-family: 'Poppins Regular';
  letter-spacing: 0.6px;
  cursor: pointer;
  text-align: center;
  background-color: #ff9c39;
  color: #fff;
}
