/* Map lengend Styles  */
.map-container__map-legend {
  grid-row: 5 / 11;
  grid-column: 1 / 3;
}

.map-legend {
  background-color: rgba(255, 255, 255, 0.918);
  display: flex;
  flex-flow: column nowrap;
  margin: 0px 0px 20px 20px;
  width: max-content;
  border-radius: 8px;
  z-index: 10000;
  border: 1px solid rgba(112, 112, 112, 0.42);
  max-width: 15vw;
  overflow-x: hidden;
  max-height: 40vh;
}

.map-legend__heading {
  font-family: 'Poppins Light';
  font-size: 0.9em;
  font-weight: 700;
  color: rgb(77, 77, 77);
  padding: 10px 55px;
  border-bottom: 1px solid rgba(112, 112, 112, 0.2);
  margin-block-start: 0px;
  margin-block-end: 0px;
  margin: 5px 0px;
  text-align: center;
}

.legend-wrapper {
  overflow-y: scroll;
  overflow-x: hidden;
  margin: 3px 3px;
}

.legend-wrapper::-webkit-scrollbar {
  width: 6px;
  background-color: rgba(117, 115, 115, 0.73);
  margin: 2px 0px;
}

.legend-wrapper::-webkit-scrollbar-thumb {
  border-radius: 5em;
  -webkit-box-shadow: inset 0 0 6px rgba(147, 147, 147, 0.2);
  box-shadow: inset 0 0 6px rgba(147, 147, 147, 0.2);
  background-color: rgba(147, 147, 147, 0.7);
  margin: 10px 0px;
}

.legend-wrapper::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(70, 70, 70, 0.1);
  border-radius: 5em;
  background-color: rgba(255, 255, 255, 0.85);
  margin: -1px 0px;
}

.legend-items {
  display: flex;
  flex-flow: column nowrap;
  align-items: stretch;
  padding-top: 0px 5px;
  box-shadow: -30px 0px 5px 0px rgba(112, 112, 112, 0.7);
  -webkit-box-shadow: 10px 0px 5px 0px rgba(112, 112, 112, 0.7);
  -moz-box-shadow: 10px 0px 5px 0px rgba(112, 112, 112, 0.7);
}

.legend-items__item {
  display: flex;
  flex-flow: row nowrap;
  font-size: 0.7em;
  font-family: 'Montserrat Regular';
  font-weight: 400;
  padding: 4px 10px;
  margin: 1px 15px;
}

.legend-items__item span:nth-child(1) {
  flex-basis: 0.85em;
  min-width: 0.95em;
  height: 0.95em;
  border-radius: 50%;
  display: inline-block;
  margin-right: 10px;
  position: relative;
  top: 3px;
}

.legend-items__item span:nth-child(2) {
  display: inline-block;
  line-height: 1.8em;
  overflow-wrap: break-word;
  text-transform: capitalize;
}

.legend-items__item span:nth-child(2) {
  text-transform: capitalize;
}
