/*
  Signin-form Element
  -   Sets the layout for wrt to the main signin container
  
*/
.error-message {
  background-color: #fce4e4;
  border: 1px solid #fcc2c3;
  float: left;
  padding: 20px 30px;
}
.error-message-text {
  font-family: 'Poppins SemiBold';
  color: #cc0033;
  font-size: 13px;
  font-weight: bold;
  line-height: 20px;
  text-shadow: 1px 1px rgba(250, 250, 250, 0.3);
}
.signin-container__signin-form {
  grid-row: 2 / 10;
  grid-column: 1 / 2;
}

/* Signin-form Block Layout - Sets the layout for the signin-form conatainer - flexbox   */
.signin-form {
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  justify-content: space-evenly;
  justify-items: stretch;
  padding: 60px 0px;
  margin: 10px 65px;
  /* border-radius: 20px;
  border: 1px solid #96939362;
  box-shadow: 0px 10px 10px #9693933f; */
}

/* Sets the dimensions for the logo image */
.signin-form__logo img {
  width: 15em;
  height: 2.3em;
  margin-top: 30px;
  margin-bottom: 13px;
}

/* Signin form info section  */
.signin-form__info {
  margin-top: 25px;
  font-family: 'Poppins Regular';
  font-size: 1.2em;
  color: #707070b4;
}

/* Sets up the line after info section */
.signin-form div.hr {
  width: 3em;
  border-radius: 0.8em;
  border: none;
  opacity: 1;
  padding: 5px 0px;
  margin: 7px;
  background-color: #ff9c39;
}

/* Sets the login form text */
.signin-form__heading {
  font-family: 'Montserrat Light';
  font-size: 1.2em;
  color: #ff9c39;
  margin-bottom: 50px;
}

/* Register text styles  */
.signin-form__register-text {
  font-family: 'Poppins SemiBold';
  margin-top: 0px;
  font-size: 0.8em;
  color: #4d4d4d8e;
  margin-bottom: 25px;
}

/* Sets the styles for the link in register text  */
.signin-form__register-text a {
  color: #03a9f4;
  margin-left: 5px;
}

/* input-box block - creates a container for the icon and input icon */
.input-box {
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: baseline;
  border: 1px solid #96939383;
  border-radius: 7px;
  margin-bottom: 30px;
  width: 30vw;
}

/* Sets up the icon flexbox to align it  */
.input-box__icon {
  display: flex;
  justify-items: center;
  align-items: center;
}

/* sets up the size of the icon of input boxes */
.input-box__icon img {
  width: 1.6em;
  height: 1.6em;
  padding: 10px 25px;
  opacity: 0.7;
}

/* email and password icons are different in size - this styles adjust the size of the password icon with the page */
/* .input-box__icon img.password-icon {
  height: 1.4em;
  width: 1.8em;
  padding: 12px 15px;
  opacity: 0.7;
} */

/* sets up the styles for input form element  */
.input-box__input {
  border: 1px solid transparent;
  flex-grow: 2;
  color: #707070be;
  padding: 0px 15px;
  border-left: 1px solid #96939362;
  flex-basis: 60%;
  margin: 5px;
  font-size: 0.9em;
  line-height: 1.3em;
  align-self: stretch;
}

/* Sets up the styles when the input boxes are focused - deals with black outline around inputs when typing */
.input-box__input:focus-visible {
  outline-color: transparent;
  outline-width: 0px;
}

/* Sets up the signin form submit button  */
.signin-form__submit-btn {
  flex-grow: 0;
  align-self: center;
  justify-self: center;
  font-family: 'Montserrat Light';
  padding: 12px 30%;
  margin: 4% 0%;
  border: none;
  /* text-transform: uppercase; */
  color: #fff;
  background-color: #ff9c39;
  border-radius: 7px;
  cursor: pointer;
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: center;
}

.signin-form__submit-btn h6 {
  margin: 0px 0px;
  font-size: 1.2em;
  letter-spacing: 1px;
  background-color: transparent;
}

svg#loading-svg-indicator {
  width: 3em;
  height: 1.5em;
  margin-left: 10px;
}

signin-from__submit-btn--loading {
  background-color: #70707069 !important;
}

.input-box__input:-webkit-autofill,
.input-box__input:-webkit-autofill:hover,
.input-box__input:-webkit-autofill:focus,
.input-box__input:-webkit-autofill:active {
  box-shadow: 0 0 0 60px rgb(255, 255, 255) inset !important;
  border-top: 0px;
  border-bottom: 0px;
  border-right: 0px;
}

.input-box__input:-webkit-autofill {
  -webkit-text-fill-color: #707070e3 !important;
}
