/* Zone Feature Menu - Feature selection Button tray */
.zone-feature-menu {
  display: flex;
  flex-flow: column nowrap;
  margin-top: 20px;
}

.zone-feature-menu__selection-menu-items {
  padding: 15px 15px;
  border-top: 1px solid #7070702d;
  border-bottom: 1px solid #7070702d;
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-around;
}

.zone-feature-menu__selection-menu-items button {
  /* margin-right: 20px; */
  background-color: transparent;
  border: none;
  border-radius: 3px;
  font-family: 'Montserrat Medium';
  padding: 7px 15px;
  cursor: pointer;
  font-size: 0.8em;
  color: #707070;
}

.zone-feature-menu__selection-menu-items .selection-menu-items--active {
  color: #fa8919;
  background-color: transparent;
  letter-spacing: 0.05px;
  border: 1px solid #ff9c39;
}

.zone-selection-data {
  max-height: 30vh;
  height: 32vh;
}

/* Building Controls Table Styles */
.building-controls-table,
.building-controls-table__item {
  display: flex;
  flex-flow: column nowrap;
}

.building-controls-table__item {
  margin: 10px 0px;
}

.building-controls-table {
  margin: 0px 15px;
}

.building-controls-table__item h3 {
  font-family: 'Montserrat Regular';
  font-size: 0.8em;
  color: #333;
  text-transform: uppercase;
  letter-spacing: 0.85px;
}

.building-controls-table__item span {
  display: block;
  font-size: 0.9em;
  color: #707070;
  letter-spacing: 0.85px;
}
