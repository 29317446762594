.response-status-menu {
  display: flex;
  flex-flow: row nowrap;
}

.response-status-menu > button {
  background-color: #fff;
  border: none;
  color: #707070;
  display: flex;
  flex-flow: row nowrap;
  font-size: 0.8em;
  padding: 5px 12px;
  border-radius: 3px;
  cursor: pointer;
}

.response-status-menu > button > div {
  width: 0.7em;
  height: 0.7em;
  background-color: black;
  margin-right: 7px;
  justify-self: center;
  align-self: center;
  border-radius: 48%;
}

.response-status-menu > button > div.ok-btn-icon {
  background-color: #69b317;
}

.response-status-menu > button > div.fail-btn-icon {
  background-color: #df677e;
}

.response-status-menu .response-code--active {
  background-color: #e7e7e7;
}
