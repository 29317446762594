.map-container__zone-feature-tray {
  grid-column: 7 / 11;
  grid-row: 1 / 11;
  background-color: rgba(255, 255, 255, 0.945);
  margin-left: 8.5vw;
  /* margin-right: 5px; */
  /* margin-top: -40px; */
  z-index: 100000;
  /* margin-bottom: -20px; */
  padding: 10px 0px;
  border-radius: 0px;
  border: 1px solid #8c8c8c23;
  /* max-height: 80vh; */
  height: auto;
}

.zone-feature-tray__feature-heading {
  display: flex;
  flex-flow: row nowrap;
  border-bottom: 1px solid #7070703f;
}

.zone-feature-tray__feature-heading h1 {
  flex-basis: 90%;
  text-align: center;
  margin-left: 35px;
  font-size: 1em;
  font-family: 'Montserrat Regular';
  text-transform: uppercase;
  letter-spacing: 1px;
  color: rgba(51, 51, 51, 0.801);
}

.zone-feature-tray__feature-heading button {
  flex-basis: 10%;
  border: none;
  cursor: pointer;
  background-color: transparent;
}

.zone-feature-tray__feature-heading button img {
  width: 0.8em;
  height: 0.8em;
  padding-right: 18px;
}

h1.feature-heading__heading-zoning {
  text-align: left;
  color: #ff9c39;
  margin-left: 15px !important;
  font-size: 0.85em;
}

.zone-feature-tray__heading-row {
  display: flex;
  flex-flow: row nowrap;
  padding: 10px 15px 0px 15px;
  font-size: 0.95em;
}

.zone-feture-tray__heading {
  color: #ff9c39;
  font-size: 0.9em;
  flex-basis: 90%;
  line-height: 1em;
}

.zone-feaure-tray__address {
  font-size: 1.2em;
  line-height: 1.8em;
  font-family: 'Montserrat Regular';
  color: #2e3191;
  min-height: 10vh;
  max-height: 10vh;
  padding: 15px 10px 15px 15px;
  margin-block-start: 0px;
  margin-block-end: 0px;
}

.zone-feature-tray__details-heading {
  font-size: 0.8em;
  margin-top: 30px;
  font-family: 'Montserrat Bold';
  text-transform: uppercase;
  color: #ff9c39 !important;
  padding: 12px 15px;
}

.zone-feature-tray__feature-table {
  width: 100%;
  padding: 0px 15px;
}

.zone-feature-tray__table-wrapper {
  min-height: 5vh;
  max-height: 32vh;
  overflow-y: scroll;
  margin: 10px 10px 10px 10px;
}

.zone-feature-tray__table-wrapper::-webkit-scrollbar {
  width: 6px;
  background-color: rgba(117, 115, 115, 0.73);
  margin: 2px 0px;
}

.zone-feature-tray__table-wrapper::-webkit-scrollbar-thumb {
  border-radius: 5em;
  -webkit-box-shadow: inset 0 0 6px rgba(147, 147, 147, 0.2);
  box-shadow: inset 0 0 6px rgba(147, 147, 147, 0.2);
  background-color: rgba(147, 147, 147, 0.7);
  margin: 10px 0px;
}

.zone-feature-tray__table-wrapper::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(70, 70, 70, 0.1);
  border-radius: 5em;
  background-color: rgba(255, 255, 255, 0.85);
  margin: -1px 0px;
}

.zone-feature-tray__feature-table td:nth-child(1) {
  font-family: 'Montserrat Regular' !important;
  font-size: 0.8em;
  display: block;
  letter-spacing: 0.85px;
  color: #333 !important;
  text-transform: uppercase;
  padding: 20px 0px;
  line-height: 1.5em;
  font-weight: 700;
}

.zone-feature-tray__feature-table td:nth-child(2) {
  font-family: 'Poppins Regular';
  text-align: left;
  font-size: 0.9em;
  line-height: 1.8em;
}
