.map-container__parcel-download {
  grid-column: 5 / 7;
  grid-row: 2 / 8;
  z-index: 10000;
}

.parcel-download {
  background-color: rgba(255, 255, 255, 0.925);
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  margin-top: -30px;
  margin-left: 30px;
  border: 1px solid #8c8c8c8a;
  border-radius: 7px;
  width: 100%;
  height: max-content;
}

.parcel-download__heading-row {
  display: flex;
  flex-flow: row nowrap;
  border-bottom: 1px solid #7070703f;
  width: 100%;
}

.parcel-download__heading {
  padding: 7px 0px 7px 12px;
  font-family: 'Montserrat Bold';
  text-transform: uppercase;
  font-size: 0.95em;
  line-height: 1.5em;
  width: 100%;
  text-align: center;
  color: #707070;
}
.parcel-download__heading-row button {
  border: none;
  background-color: transparent;
  padding-right: 20px;
  cursor: pointer;
}

.parcel-download__heading-row button img {
  width: 0.7em;
  height: 0.7em;
}

.parcel-form {
  display: flex;
  flex-flow: column nowrap;
  width: 100%;
}

.parcel-form-item {
  padding: 0px 15px;
  margin: 10px 0px;
}
.parcel-form-item__heading {
  font-size: 0.85em;
  font-family: 'Poppins Light';
  color: #707070;
  margin: 20px 0px;
  padding: 12px 5px;
  margin-block-start: 0px;
  margin-block-end: 0px;
}

.parcel-form-item__heading span {
  font-size: 0.7em;
  display: inline-block;
  position: relative;
  top: -5px;
  margin-left: 2px;
}

.parcel-form-item input {
  padding: 10px 10px;
  width: 89%;
  font-size: 0.85em;
  color: #707070;
  background-color: #fff;
  font-family: 'Poppins Regular';
  border: 1px solid #7070703f;
  border-radius: 5px;
}

.parcel-form-item input:focus-visible {
  outline-color: transparent;
  outline-width: 0px;
}

.download-parcel-btn {
  margin: 15px 15px;
  border: none;
  background-color: #ff9c39;
  padding: 10px 0px;
  border-radius: 5px;
  color: #fff;
  font-size: 0.9em;
  cursor: pointer;
}
