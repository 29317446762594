.change-password-form {
  margin: 20vh 20px;
}

.change-password-form__heading {
  font-size: 1.2em;
  color: #707070c4;
  font-family: 'Montserrat Bold';
  text-transform: capitalize;
}

.change-passsword-form__btn {
  flex-grow: 0;
  font-family: 'Montserrat Medium';
  padding: 12px 30%;
  margin: 30px 0px;
  border: none;
  color: #fff;
  background-color: #ff9c39;
  border-radius: 7px;
  cursor: pointer;
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: center;
  width: 95%;
}
