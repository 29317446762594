.dropdown-btn {
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  justify-items: center;
  align-items: stretch;
  border-radius: 5px;
  background-color: transparent;
  cursor: pointer;
  border: 1px solid rgba(112, 112, 112, 0.32);
  color: rgba(77, 77, 77, 0.85);
  font-size: 0.75em;
}

.dropdown-btn__text {
  text-align: left;
  align-self: center;
  font-weight: 400;
  max-width: 19em;
  flex-basis: 70%;
  font-size: 1.1em;
  line-height: 1.38em;
  font-family: 'Poppins Light';
  color: rgb(77, 77, 77);
  letter-spacing: 0.5px;
  text-overflow: ellipsis;
  overflow-x: hidden;
  white-space: nowrap;
}

.dropdown-btn hr {
  width: 1px;
  height: 2em;
  background-color: rgba(112, 112, 112, 0.7);
  border: none;
  align-self: center;
}

.dropdown-btn span {
  align-self: center;
}

.dropdown-btn span img,
.dropdown-btn span img #loading-svg-indicator {
  max-width: 1em;
  height: 0.8em;
  padding: 6px auto;
  color: black;
}
