/* API CONTAINER STYLES  */
.application-container__api-container {
  grid-row: 2 / 10;
  grid-column: 1 / 4;
}

.api-container {
  display: flex;
  flex-flow: row nowrap;
  width: 100%;
  /* background-color: #faf9f9; */
}
