.offmarket-filter__filter-menu {
  display: flex;
  flex-flow: row wrap;
  justify-content: flex-start;
  align-items: baseline;
  margin: 0px 0px;
  padding: 0px 0px;
}

.offmarket-filter__filter-menu > div.filter-control {
  flex-basis: 44% !important;
  max-width: 44%;
  margin: 50px 2% 0px !important;
  font-size: 1em;
}

.offmarket-filter__filter-menu > div.filter-control:first-child {
  flex-basis: 94% !important;
  max-width: 98%;
}

.offmarket-filter__filter-menu input[type='date'] {
  margin: 0px 0px;
  flex-basis: 100% !important;
  max-width: 95%;
  padding: 10px 15px;
  font-size: 0.8em;
}

.offmarket-filter__filter-menu > div img {
  height: 1em !important;
}

.offmarket-filter__filter-menu button {
  font-size: 0.7em;
}

/* Overriding the dropdwon list wrapper styles to fix width */
.offmarket-filter__filter-menu .filter-control__list-wrapper {
  min-width: 97% !important;
  min-height: min-content !important;
  max-height: 20vh !important;
  font-size: 0.95em;
  border-radius: 2px;
}
