.landing-search {
  grid-row: main-heading-end / search-development-end;
  grid-column: 1 / 11;
  display: grid;
  grid-template-rows: repeat(10, 10vh);
  gap: 10px;
  grid-template-columns: 20vw 60vw 20vw;
  background: linear-gradient(90deg, #ff9c39 0%, #ff9c39 100%) 0vw 20vh / 100vw
    60vh no-repeat padding-box;
}

.landing-search__heading {
  grid-row: 4 / 5;
  grid-column: 2 / 3;
  font-family: 'Poppins Light', sans-serif;
  font-size: 1.3em;
  letter-spacing: 0.2px;
  text-align: center;
  font-size: 1.8em;
  margin-top: -2px;
}

.search-heading__img {
  width: 1em;
  height: 1em;
  padding-right: 15px;
}

/* Override the search suggestion component styles  */

.landing-search .submit-btn--active {
  background-color: #fff5eb;
  color: #4d4d4d;
  box-shadow: none;
}

.landing-search .search-form__input--tray-active {
  border-top: 1px solid rgba(112, 112, 112, 0.3);
  border-left: 1px solid rgba(112, 112, 112, 0.3);
}

.landing-search .suggestions--active {
  border-left: 1px solid rgba(112, 112, 112, 0.3);
  border-bottom: 1px solid rgba(112, 112, 112, 0.3);
  border-right: 1px solid rgba(112, 112, 112, 0.3);
}

.landing-search__btns {
  grid-row: 6 / 7;
  grid-column: 2 / 3;
  display: flex;
  flex-flow: row nowrap;
  margin-top: 20px;
  justify-content: center;
}

.btns-row__btn {
  background-color: #4d4d4d;
  border: none;
  color: #fff;
  margin-right: 10px;
  font-size: 1.2em;
  padding: 5px 60px;
  border-radius: 10px;
  line-height: 2em;
}
