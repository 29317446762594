.landing-featured {
  grid-row: information-end / footer-start;
  grid-column: 1 / 11;
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
}

.landing-featured__heading {
  font-size: 3em;
  font-family: 'Montserrat Bold', sans-serif;
  text-transform: uppercase;
}

.landing-featured__line {
  width: 10em;
  height: 1em;
  margin-top: -30px;
  border-radius: 10px;
  background-color: #ff9c39;
}

.landing-featured__img {
  width: 80vw;
  margin: 30px 20vw 0px;
}
