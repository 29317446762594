.api-credentials-content {
  margin: 10vh 5vw;
}

.api-crendentials-content__heading {
  color: #707070;
  text-transform: uppercase;
  font-family: 'Montserrat Bold';
  font-size: 1.7em;
}

.api-crendentials-content__api-crendentials-details {
  border: 1px solid #7070704d;
  padding: 15px 25px;
  border-radius: 7px;
  margin-top: 30px;
  min-width: 25vw;
  width: 40vw;
  min-height: 3vh;
  color: #707070;
}
