.main-grid {
  display: grid;
  grid-template-columns: repeat(10, 10%);
  grid-template-rows: [header-start] 10vh [header-end] 90vh [main-heading-end] 100vh [search-development-end] 100vh [information-end] 100vh [footer-start] 60vh [footer-end];
  background: linear-gradient(
        0deg,
        rgba(255, 255, 255, 0.3) 0%,
        rgba(255, 255, 255, 0.275) 5%,
        rgba(255, 255, 255, 0.2) 10%,
        rgba(255, 255, 255, 0.1) 40%,
        rgba(255, 255, 255, 1) 100vh
      )
      0% 0% no-repeat border-box,
    url('../../assets/images/sydney-panaromic.jpg') no-repeat 0px 0vh;
  background-size: 120vw 100vh;
  background-attachment: local;
}
