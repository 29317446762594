.marketing {
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;

  background: linear-gradient(
        0deg,
        rgba(138, 138, 138, 0.41) 0%,
        rgba(138, 137, 137, 0.76) 5%,
        rgba(168, 167, 167, 0.8) 10%,
        rgba(202, 201, 201, 0.8) 40%,
        rgba(255, 255, 255, 0.651) 100vh
      )
      0% 0% no-repeat border-box,
    url('../../assets/images/webinar-banner.webp') no-repeat 0px 0vh;
  background-size: 100vw 100vh;
}

/* WEBINAR HEADING SECTION */
.marketings__marketing-heading {
  grid-row: header-start / header-end;
  grid-column: 1 / 11;
  width: 100vw;
  height: 100vh;
}

.marketing-heading {
  margin-top: 12vh;
  text-align: center;
  flex-basis: 100vw;
}

.marketing-heading__heading {
  font-family: 'Montserrat Bold';
  font-size: 2.5em;
  color: #fff;
  text-transform: uppercase;
  margin: 0px auto;
  margin-bottom: 55px;
  width: max-content;
  background: #ff9c39;
  padding: 10px 28px;
}

.marketing-heading__sub-heading {
  font-family: 'Montserrat Bold';
  font-size: 1.75em;
  line-height: 1.7em;
  color: #3f3e3e;
  text-align: center;
}

.marketing-heading__details {
  font-size: 1.85em;
  color: #3f3e3e;
  font-family: 'Montserrat Bold';
  line-height: 2em;
  /* max-width: fit-content; */
  text-align: center;
  margin: 0% 10%;
}

.marketing-heading__more-btn {
  margin-top: 70px;
  border: none;
  font-family: 'Poppins Medium';
  text-transform: capitalize;
  background-color: #ff9429;
  border-radius: 7px;

  color: #fff;
  padding: 12px 30px;
  font-size: 1.2em;
  line-height: 1.5em;
  letter-spacing: 0.5px;
  cursor: pointer;
  opacity: 1;
  z-index: 199;
}

.marketing-heading__more-btn img {
  width: 1em;
  height: 1em;
  position: relative;
  top: 3px;
  left: 5px;
}

/* UPCOMING WEBINARS SECTION */

.marketing-item-container {
  width: 100vw;
  display: flex;
  flex-flow: row nowrap;
}

.marketing-item-container > div {
  flex-basis: 50vw;
}

.marketings-container__left {
  flex-basis: 45vw;
  padding: 15vh 10vw;
  display: flex;
  align-items: left;
  flex-direction: column;
  justify-content: center;
}

.marketings-container__heading {
  font-size: 1.1em;
  color: #f1800e;
  text-transform: uppercase;
  font-family: 'Montserrat Bold';
  width: fit-content;
  line-height: 2em;
  position: relative;
  margin-bottom: 50px;
}

.marketings-container__heading::before {
  content: ' ';
  position: absolute;
  width: 100%;
  height: 5px;
  background-color: #ff9c39;
  top: -20%;
  left: 0%;
  border-radius: 2.5em;
}

.marketings-item {
  display: flex;
  flex-flow: column nowrap;
  width: 30vw;
}

.marketings-item__heading {
  font-size: 2.3em;
  line-height: 1.4em;
  text-transform: uppercase;
  font-family: 'Montserrat Bold';
  margin: 10px 0px;
}

.marketings-item__webinar-details {
  font-family: 'Poppins Regular';
  font-size: 1.11em;
  line-height: 1.8em;
  color: #707070;
}

.marketings-item__date-time-details {
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  color: #707070c9;
  font-size: 1em;
  margin-top: 20px;
  font-family: 'Poppins Regular';
}

.marketings-item__date-time-details img {
  width: 1em;
  height: 1em;
  margin-right: 7px;
  line-height: 1em;
}

.marketings-item__register-btn {
  width: fit-content;
  padding: 10px 15px;
  border: none;
  font-size: 1em;
  color: #fff;
  background-color: #ff9c39;
  margin-top: 50px;
  font-family: 'Poppins SemiBold';
  border-radius: 5px;
}

.marketing-container__right {
  padding: 15vh 5vw;
  flex-basis: 50%;
}

.marketings-image img {
  padding-top: 20vh;
  width: 35vw;
  height: auto;
  margin-left: -3vw;
}

.marketing__upcoming-marketing {
  grid-row: header-end / marketings-addproduct;
  grid-column: 1 / 11;
}

.ondemand-marketing__content {
  font-size: 1em;
  line-height: 1.6em;
  font-family: 'Montserrat Bold';
}

.coverage-form .marketing-container__right .coverage-form-item {
  display: flex;
  flex-flow: column nowrap;
  margin: 20px 0px;
}

.coverage-form .marketing-container__right .coverage-form-item input {
  line-height: 3em;
  border-radius: 7px;
  border: 1px solid #8c8c8c7e;
  padding: 3px 15px;
  color: #707070;
  font-size: 0.95em;
}

.coverage-form
  .marketing-container__right
  .coverage-form-item
  input:focus-visible {
  outline-color: transparent;
  outline-width: 0px;
}

.coverage-form .marketing-container__right .coverage-form-item h3 {
  font-family: 'Poppins Regular';
  font-size: 0.9em;
  color: #707070;
  text-transform: uppercase;
}

.coverage-form .marketing-container__right .coverage-form-item textarea {
  line-height: 1em;
  border-radius: 7px;
  font-size: 0.95em;
  border: 1px solid #8c8c8c7e;
  padding: 15px 10px;
  color: #707070;
}

.coverage-form
  .marketing-container__right
  .coverage-form-item
  textarea:focus-visible {
  outline-color: transparent;
  outline-width: 0px;
}

.marketings-coverage__submit {
  flex-grow: 0;
  align-self: center;
  justify-self: center;
  font-family: 'Poppins Regular';
  padding: 12px 30%;
  margin: 4% 0%;
  border: none;
  /* text-transform: uppercase; */
  color: #fff;
  background-color: #ff9429;
  border-radius: 7px;
  cursor: pointer;
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.ondemand-marketing__content {
  font-size: 1em;
  line-height: 1.6em;
  font-family: 'Montserrat Bold';
}

.marketing-container__on-demand-marketing .marketings-container__left {
  /* padding-top: 30vh; */
}

.marketing-container__on-demand-marketing .marketing-container__right {
  padding: 12vh 0px;
  margin: 0px 0px;
  display: flex;
  flex-flow: row wrap;
  margin-left: -5vw;
}

.marketing-container__on-demand-marketing .marketing-container__right > div {
  flex-basis: 14vw;
  line-height: 1.5em;
  height: 20vh;
  margin-right: 30px;
  margin-bottom: 10px;
  margin-top: 0px;
  border: 1px solid #70707038;
  border-radius: 7px;
  padding: 10px 20px 25px 20px;
  display: flex;
  flex-flow: column nowrap;
}

.marketing-container__on-demand-marketing
  .marketing-container__right
  > div
  > .on-demand__heading {
  font-size: 0.85em;
  line-height: 1em;
  margin: 15px 0px 5px 0px;
}

.marketing-container__on-demand-marketing
  .marketing-container__right
  > div
  > p {
  font-size: 0.75em;
  flex-basis: 17vw;
}

.on-demand-item > a {
  font-size: 0.9em;
  color: #0058ff;
  justify-self: flex-end;
}

.on-demand-item > a > img {
  width: 1em;
  height: 1em;
  position: relative;
  top: 2px;
  left: 3px;
}
.coverage-form-marketing {
  grid-row: coverage-form / footer-start;
  grid-column: 1 / 11;
  display: flex;
  width: 100vw;
  flex-flow: row nowrap;
}
.coverage-form-marketing
  .marketing-container__right
  .coverage-form-item
  textarea {
  line-height: 1em;
  border-radius: 7px;
  font-size: 0.95em;
  border: 1px solid #8c8c8c7e;
  padding: 15px 10px;
  color: #707070;
}

.coverage-form-marketing .marketing-container__right .coverage-form-item input {
  line-height: 3em;
  border-radius: 7px;
  border: 1px solid #8c8c8c7e;
  padding: 3px 15px;
  color: #707070;
  font-size: 0.95em;
}

.coverage-form-marketing .marketing-container__right .coverage-form-item h3 {
  font-family: 'Poppins Regular';
  font-size: 0.9em;
  color: #707070;
  text-transform: uppercase;
}
.coverage-form-marketing .marketing-container__right .coverage-form-item {
  display: flex;
  flex-flow: column nowrap;
  margin: 20px 0px;
}
