.api-item {
  display: flex;
  flex-flow: column nowrap;
  margin: 10px 0px;
}

.api-item__content {
  display: flex;
  flex-flow: row nowrap;
  margin: 0px 25px 0px 10px;
  cursor: pointer;
  flex-basis: 80%;
}

.api-item__content--active .api-item__heading {
  color: #ff9c39 !important;
}

.api-item__heading {
  flex-grow: 2;
  color: #707070;
  font-size: 0.8em;
  padding-left: 15px;
  font-family: 'Montserrat Light';
}

.api-item__content > span {
  display: block;
  justify-self: flex-end;
  align-self: center;
}

.api-menu-items__api-item span img {
  width: 0.8em;
  height: 0.8em;
}

.right-icon--active {
  transform: rotate(90deg);
}
