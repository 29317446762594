/* API MENU STYLES  */
.api-container__api-menu {
  flex-basis: 20%;
  max-width: 20%;
  max-height: 90vh;
  overflow-y: hidden;
}

.api-container__api-menu:hover {
  overflow-y: auto;
}

.api-container__api-menu::-webkit-scrollbar {
  width: 6px;
  background-color: rgba(223, 223, 223, 0.418);
}

.api-container__api-menu::-webkit-scrollbar-thumb {
  border-radius: 16px;
  -webkit-box-shadow: inset 0 0 6px rgba(241, 235, 235, 0.116);
  box-shadow: inset 0 0 6px rgba(147, 147, 147, 0.2);
  background-color: rgba(147, 147, 147, 0.199);
  padding: 0px 10px;
}

.api-container__api-menu::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(158, 154, 154, 0.562);
  border-radius: 16px;
  padding: 5px;
  background-color: rgba(255, 255, 255, 0.85);
}

.api-menu {
  display: flex;
  flex-flow: column nowrap;
  background-color: #b6b6b60a;
  border-right: 1px solid #7070702c;
}

.api-menu__introduction {
  margin: 10px 0px 30px 0px;
  display: flex;
  flex-flow: column nowrap;
  align-items: stretch;
}

.highlight {
  color: #fa9d40 !important;
}

.api-credentials {
  margin: 25px 25px 0px 25px;
  color: #707070cc;
  background-color: transparent;
  border: none;
  font-weight: 700;
  font-size: 0.8em;
  cursor: pointer;
  text-align: left;
  letter-spacing: 0.7px;
  font-family: 'Montserrat Light';
}

.api-menu__heading {
  font-family: 'Montserrat Light';
  text-transform: uppercase;
  color: rgb(43, 42, 42);
  font-size: 0.8em;
  letter-spacing: 0.9px;
  margin: 35px 5px 0px 22px;
}

.api-menu-items {
  display: flex;
  flex-flow: column nowrap;
  margin: 12px 0px 0px 0px;
}
