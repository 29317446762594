/* BLOCK - development-table - sets the styles for the development-table block   */
table.development-table {
  font-family: 'Poppins Regular';
  font-size: 0.85em;
  line-height: 2em;
  text-align: center;
  color: #4d4d4d;
  margin-top: 70px;
  width: 100%;
}

table.development-table thead tr {
  border-collapse: collapse;
  border: none;
}

table.development-table thead th {
  /* border-collapse: collapse; */
  border: none;
  background-color: #f5f6fa;
  padding: 10px 0px;
  font-family: 'Poppins SemiBold';
  font-size: 0.9em;
  text-transform: uppercase;
}
