/* Header Nav - Sets styles with respect ot the header */
.application-header__nav {
  flex-grow: 4;
}

/* Nav Block - Sets the layout for the Nav bar in header */
.application-nav {
  display: flex;
  height: 100%;
  align-self: flex-end;
  flex-flow: row nowrap;
  align-items: center;
  justify-items: center;
}

.application-nav--hidden {
  visibility: hidden;
}

/* Nav Link elements styles definitions  */
.application-nav__link {
  color: inherit;
  text-decoration: none;
  margin-right: 5px;
  padding: 1em 1.3em;
  font-family: 'Poppins Medium', sans-serif;
  font-size: 0.9em;
  color: #707070;
  width: max-content;
}

/* Nav Link link element active modifier styles */
.application-nav__link--active::after {
  content: ' ';
  display: block;
  width: 70%;
  height: 6.3px;
  position: relative;
  background-color: #ff9c39;
  position: relative;
  left: 14%;
  top: 18px;
  z-index: 10;
  border-radius: 50px;
}
