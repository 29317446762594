/* MAP CONTAINER: planning DETAILS TRAY STYLES */

.map-container__planning-tray {
  grid-column: 7 / 11;
  grid-row: 1 / 11;

  z-index: 100000;
}

.planning-tray {
  display: flex;
  flex-flow: column nowrap;
  justify-items: stretch;
  align-items: stretch;
}

.planning-tray__planning-heading {
  justify-self: stretch;
}

.planning-heading {
  display: flex;
  flex-flow: row nowrap;
  border-bottom: 1px solid rgba(112, 112, 112, 0.281);
}

.planning-heading__toggle-btn {
  border: none;
  background-color: transparent;
  margin-left: 15px;
  cursor: pointer;
  z-index: 100000;
}
.planning-heading__toggle-btn img {
  width: 15px;
  height: 15px;
  cursor: pointer;
}

.planning-heading__heading {
  flex-grow: 2;
  text-align: center;
  margin-left: -70px;
  font-family: 'Poppins Regular';
  font-size: 1.2em;
  letter-spacing: 0.7px;
  color: rgba(77, 77, 77, 0.74);
}

.planning-tray__planning-content {
  justify-self: stretch;
  margin: 10px 0px 10px 10px;
}

.planning-content {
  display: flex;
  flex-flow: column nowrap;
  justify-items: stretch;
  align-items: stretch;
  overflow-y: scroll;
  margin-block-start: 0px;
  margin-block-end: 0px;
  margin: 0px 10px 10px 10px;
}

.planning-content::-webkit-scrollbar {
  width: 6px;
  background-color: rgba(117, 115, 115, 0);
  padding: 5px;
  overflow: hidden;
}

.planning-content::-webkit-scrollbar-thumb {
  border-radius: 16px;
  padding: 5px;
  -webkit-box-shadow: inset 0 0 6px rgba(147, 147, 147, 0);
  box-shadow: inset 0 0 6px rgba(147, 147, 147, 0.2);
  background-color: rgba(147, 147, 147, 0.7);
}

.planning-content::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(70, 70, 70, 0);
  border-radius: 16px;
  padding: 10px;
  margin: 10px;
}

.planning-item {
  display: flex;
  flex-flow: column nowrap;
  align-items: flex-start;
  justify-items: stretch;
  margin-block-start: 0px;
  margin-block-end: 0px;
  padding-bottom: 20px;
  border-bottom: 1px solid rgba(112, 112, 112, 0.301);
  margin: 0px 10px;
}

.planning-item__heading {
  font-size: 1.1em;
  line-height: 1.8em;
  color: rgb(0, 135, 253);
  font-family: 'Poppins Regular';
  letter-spacing: 0.5px;
}

.planning-item__sub-heading {
  font-size: 0.8em;
  margin-block-start: 0px;
  margin-block-end: 0px;
  margin-bottom: 20px;
}

.planning-item__date {
  font-size: 0.8em;
  font-family: 'Poppins Light';
  width: max-content;
  text-align: center;
  margin-block-start: 0px;
  margin-block-end: 0px;
  color: rgba(112, 112, 112, 0.8);
}

.planning-item__date img {
  width: 1.2em;
  height: 1.2em;
  margin-right: 10px;
  margin-bottom: -1px;
}

.planning-item__planning-details {
  margin-top: 10px;
  font-size: 0.8em;
  display: flex;
  flex-flow: row nowrap;
  justify-self: stretch;
  align-self: stretch;
}

.planning-item__planning-details p {
  flex-basis: 80%;
  line-height: 1.6em;
  overflow-wrap: anywhere;
}

.planning-item__planning-details button {
  flex-basis: 20%;
  align-self: flex-end !important;
  justify-self: flex-end;
  font-size: 1.1em;
  font-family: 'Poppins Regular';
  text-decoration: none;
  color: rgb(47, 143, 228);
  font-weight: 700;
  letter-spacing: 0.6px;
  border: none;
  background-color: transparent;
  padding: 5px 10px;
  border-radius: 5px;
  cursor: pointer;
}

.planning-item__planning-details button:hover {
  background-color: #8c8c8c4b;
}
