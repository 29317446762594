.landing-heading {
  grid-row: header-end / main-heading-end;
  grid-column: 1 / 11;
}

.landing-heading__heading {
  display: flex;
  flex-flow: column nowrap;
}

.landing-heading__heading img {
  opacity: 1;
  width: 17.4em;
  height: 3.4em;
  z-index: 100;
  margin-top: 2.5em;
  margin-bottom: -20px;
  align-self: center;
}

.landing-heading__heading h1 {
  text-align: center;
  font: normal normal bold 57px/85px Montserrat;
  font-family: 'Montserrat Bold', sans-serif;
  text-transform: uppercase;
  margin-bottom: 20px;
  word-spacing: 7px;
  opacity: 1;
}

.landing-heading__heading h1 span {
  background-color: rgb(255, 156, 57);
  color: #fff;
  padding: 0px 10px;
}

.landing-heading__heading a {
  background-color: #ff9429;
  width: max-content;
  align-self: center;
  border-radius: 7px;
  font-size: 1.2em;
  font-family: 'Poppins Medium', sans-serif;
  padding: 13px 48px;
  opacity: 1;
  color: #fff;
  margin-top: 15px;
}
