.header__links {
  flex-basis: 50vw;
  justify-self: flex-end;
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-around;
  margin-left: 10vw;
}

.header__links a {
  /* padding: 10px 0px 10px; */
  margin: 2px;
  max-width: 2.5em;
  font-size: 0.75em;
  text-transform: uppercase;
  font-family: 'Poppins SemiBold', sans-serif;
}

.header__links a:hover {
  font-size: 0.78em;
}
