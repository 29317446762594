table.modal-table tbody tr:nth-child(even) {
  background-color: rgba(212, 211, 211, 0.144);
}

table.modal-table tbody td {
  padding: 22px 12px;
}

table.modal-table tbody td:nth-child(1) {
  width: 25%;
  color: #4d4d4dea;
  font-size: 0.79em;
  text-transform: uppercase;
  letter-spacing: 0.5px;
  line-height: 1.5em;
  font-weight: 700;
  border-bottom: 1px solid rgba(194, 187, 187, 0.15);
  vertical-align: top;
  line-height: 1.8em;
  white-space: nowrap;
}

table.modal-table tbody td:nth-child(2) {
  width: 75%;
  word-wrap: break-word;
  overflow-wrap: anywhere;
  color: #707070;
  font-size: 0.85em;
  border-bottom: 1px solid rgba(194, 187, 187, 0.15);
  line-height: 1.8em;
}
