.request-menu {
  display: flex;
  flex-flow: row nowrap;
  margin: 30px 0px;
  border: 1px solid #8c8c8c34;
  width: max-content;
  border-radius: 5px;
  background-color: #fff;
}

.request-menu > button {
  background-color: transparent;
  border: none;
  color: #707070;
  font-size: 0.75em;
  line-height: 1em;
  text-transform: uppercase;
  font-family: 'Poppins Regular';
  letter-spacing: 0.5px;
  padding: 8px 30px;
  cursor: pointer;
  border-right: 1px solid #70707052;
}

.request-menu > button:nth-child(3) {
  border-right: none;
}

.request-menu > button.request-menu__request-menu-btn--active {
  background-color: #ff9c39;
  position: relative;
  color: #fff;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
  border-top: 1px solid #ff9c39;
  border-bottom: 1px solid #ff9c39;
  border-left: 1px solid #ff9c39;
  border-right: 1px solid #ff9c39;
}

.request-menu > button.request-menu__request-menu-btn--active:nth-child(2) {
  border-radius: 0px;
}

.request-menu > button.request-menu__request-menu-btn--active:nth-child(3) {
  border-radius: none;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
  border-top-left-radius: 0px;
  border-bottom-left-radius: 0px;
}
