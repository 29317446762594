/* 
      admin-application-container Gird Distribution -
      Rows - 2 - space-left: 100vh  
            - Header: 12 vh
            - Page: 88vh
      Columns - 3 
            - 1st Column - 4vw
            - 2nd Column - 90vw
            - 3rd Column - 6vw
        
        
*/

.admin-application-container {
  display: grid;
  grid-template-rows: 12vh repeat(8, 11vh);
  grid-template-columns: 4vw 90vw 6vw;
  gap: 0px;
}

/* 
                            
      HEADER BLOCK - 
      The header styles, contains styles for the logo, nav and the user info section.  
                          
*/

/* Sets header element styles wrt to the main block */
.admin-application-container__header {
  grid-row: 1 / 2;
  grid-column: 1 / 4;
}

/* Header Block - sets layout */
.admin-application-header {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  border-bottom: 1px solid rgba(112, 112, 112, 0.2);
}

/* Logo Element styles */
.admin-application-header__logo {
  flex-grow: 1.5;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: left;
  margin-left: 50px;
}

/* styles for containing the image */
.admin-application-header__logo img {
  width: 14em;
  height: 2em;
  margin-bottom: -20px;
}
