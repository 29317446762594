.suggestions__wrapper {
  margin: 2px 5px;
  max-height: 32vh;
  opacity: 1;
  border-top: 1px solid #4d4d4d1c;
  list-style-type: none;
  overflow-y: hidden;
}

.suggestions__wrapper:hover {
  overflow-y: scroll;
}
.suggestions__wrapper::-webkit-scrollbar {
  width: 6px;
  background-color: rgba(223, 223, 223, 0.418);
}

.suggestions__wrapper::-webkit-scrollbar-thumb {
  border-radius: 16px;
  -webkit-box-shadow: inset 0 0 6px rgba(238, 228, 228, 0.2);
  box-shadow: inset 0 0 6px rgba(147, 147, 147, 0.2);
  background-color: rgba(147, 147, 147, 0.7);
}

.suggestions__wrapper::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(161, 161, 161, 0.541);
  border-radius: 16px;
  padding: 5px;
  background-color: rgba(255, 255, 255, 0.85);
  margin: 5px;
}

.suggestions--active {
  display: block !important;
}
.suggestions__item {
  padding: 15px 5px;
  font-family: 'Montserrat Regular';
  color: #707070da;
  margin: 10px 13px;
  font-size: 0.85em;
  line-height: 1.8em;
  vertical-align: middle;
  /* font-weight: bold; */
  letter-spacing: 0.5px;
  display: flex;
  flex-flow: row nowrap;
  justify-items: center;
}
.suggestions__item img {
  width: 4.3%;
  height: 100%;
  max-height: 100%;
  margin-right: 15px;
  position: relative;
  /* top: 10px; */
}

.suggestions__item:hover {
  background-color: #70707015;
  cursor: pointer;
  border-radius: 13px;
}
