.landing-info-section {
  grid-row: search-development-end / information-end;
  grid-column: 1 / 11;
  display: grid;
  grid-template-rows: 25vh 25vh 25vh 25vh;
  grid-template-columns: 14.2vw 14.2vw 14.2vw 14.2vw 14.2vw 14.2vw 14.2vw;
}

.landing-info-section__heading {
  grid-row: 1 / 5;
  grid-column: 1 / 3;
  justify-content: center;
  padding: 30vh 5vw;
  font-size: 3.5em;
  text-transform: uppercase;
  font-family: 'Montserrat Bold';
}

.landing-info-section__boxes {
  grid-row: 1 / 5;
  grid-column: 4 / 7;
}

.info-box-cont {
  display: grid;
  gap: 1em;
  grid-template-rows: 25% 25% 25% 25%;
  grid-template-columns: 33.3% 33.3% 33.3%;
}

.info-box-cont__item {
  background-color: #ff9c39;
  text-align: center;
  color: #fff;
  font-family: 'Poppins Light';
  font-size: 1em;
  letter-spacing: 1px;
  padding: 23px 0px;
  border-radius: 17px;
}

.info-box-cont__item img {
  padding-top: 15px;
  width: 3.2em;
}

.search {
  grid-row: 2 / 3;
  position: relative;
  top: -70px;
}

.discover {
  grid-row: 2 / 3;
  grid-column: 2 / 3;
  position: relative;
  top: -70px;
}

.inventory {
  grid-row: 2 / 3;
  grid-column: 3 / 4;
  position: relative;
  top: -70px;
}

.monitor {
  grid-row: 3 / 4;
  grid-column: 1 / 2;
  position: relative;
  left: 90px;
  top: -70px;
}

.learn-more {
  grid-row: 3 / 4;
  grid-column: 2 / 3;
  position: relative;
  left: 90px;
  top: -70px;
}
