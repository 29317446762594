.application-container__subscribe-page {
  grid-row: 2 / 10;
  grid-column: 1 / 4;
}

.subscribe-page {
  display: flex;
  flex-flow: column nowrap;
  justify-content: stretch;
  align-items: stretch;
}

.subscribe-banner {
  display: flex;
  flex-flow: row nowrap;
  height: 50vh;
  max-height: 50vh;
  overflow: hidden;
  background-color: #f7f7f7;
  padding: 20px 30px;
}

.subscribe-banner img {
  width: 25vw;
  height: 100%;
}

.banner-content {
  display: flex;
  flex-flow: column nowrap;
  flex-basis: 50vw;
  padding-left: 15px;
}

.banner-content__heading {
  margin-top: 60px;
  font-size: 2.2em;
  font-family: 'Montserrat Bold';
  text-align: center;
  color: #707070;
  white-space: nowrap;
  justify-self: center;
  margin-bottom: 5px;
}

.banner-content__text {
  font-family: 'Montserrat Regular';
  font-size: 1.2em;
  text-align: center;
  line-height: 2.5em;
  color: #707070dc;
  justify-self: center;
  align-self: center;
}

.subscribe-banner__left-illustration {
  flex-basis: 20vw;
  height: 100%;
}

.subscribe-banner__right-illustration {
  flex-basis: 20vw;
}

.subscribe-form {
  display: flex;
  flex-wrap: nowrap;
  flex-flow: row nowrap;
  border: 1px solid #70707052;
  margin-top: 20px;
  margin: 30px 30px 0px 30px;
  border-radius: 15px;
  padding: 0px 0px;
  box-shadow: -1px 0px 8px 7px rgba(233, 232, 232, 0.378);
}

.subscribe-dropdown-btn {
  border-right: 1px solid #93939367;
  display: flex;
  flex-flow: row nowrap;
  margin-right: 20px;
  padding: 10px 20px;
  border-top-left-radius: 15px;
  border-bottom-left-radius: 15px;
  position: relative;
}

.subscribe-dropdown-btn .list-wrapper {
  top: -1px;
  left: -1px;
  border-color: #70707052;
  min-width: 18.5vw !important;
  height: 18vh;
  border-top-left-radius: 12px;
  border-bottom-left-radius: 6px;
  border-bottom-right-radius: 6px;
}

.subscribe-dropdown-btn:hover {
  background-color: #e7e6e649;
  cursor: pointer;
}

.subscribe-dropdown-btn h3 {
  font-size: 0.92em;
  font-family: 'Poppins Light';
  color: #4d4d4d;
  margin: 15px 10px;
  letter-spacing: 0.65px;
}

.subscribe-dropdown-btn hr {
  width: 1px;
  height: 1.7em;
  padding-top: 5px;
  margin-left: 5px;
  border: none;
  background-color: #4d4d4db9;
}

.subscribe-dropdown__img {
  justify-self: center;
}

.subscribe-dropdown__img img {
  width: 1em;
  height: 25%;
  margin-right: 40px;
  justify-self: center;
  position: relative;
  left: 20px;
  top: 30%;
}

.registered-items {
  display: flex;
  align-items: center;
  flex-flow: row wrap;
  justify-items: first baseline;
}

.registered-items .registered-item__postcode {
  font-family: 'Montserrat Regular';
  justify-self: center;
  align-self: center;
  border-radius: 7px;
  background-color: #ff9c39;
  color: #fff;
  margin: 0px 10px;
  padding: 9px 25px;
  font-size: 0.85em;
  letter-spacing: 0.7px;
  box-shadow: -1px 0px 8px 7px rgba(233, 232, 232, 0.575);
}
.registered-item--btn {
  position: relative;
}

.registered-item__closeBtn {
  position: absolute;
  cursor: pointer;
  z-index: 200;
  right: -3px;
  top: -11px;
  background-color: #ff9c39;
  color: #fff;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 0.5em;
  height: 0.5em;
  padding: 6px;
}

.registered-item__closeBtn img {
  width: 0.65em;
  height: 0.65em;
}

.registered-item__parent {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
.registered-item__button {
  text-transform: capitalize;
  padding: 15px 51px;
  margin: 20px 30px;
  align-self: end;
  border: none;
  width: max-content;
  border-radius: 7px;
  background-color: #ff9c39;
  color: #fff;
  cursor: pointer;
  font-size: 0.85em;
  box-shadow: -1px 0px 8px 7px rgb(233 232 232 / 57%);
}
.alert-box__success {
  font-family: 'Montserrat Regular';
  padding: 15px 51px;
  margin: 20px 30px;
  align-self: center;
  border: none;
  letter-spacing: 0.5px;
  width: max-content;
  border-radius: 7px;
  background-color: #4dca64;
  color: #fff;
  font-size: 0.85em;
  box-shadow: -1px 0px 8px 7px rgb(233 232 232 / 57%);
}
