body {
  font-family: 'Poppins Regular', sans-serif;
  color: #4d4d4d;
  overflow-x: hidden;
  font-size: 15px;
}

/* 
          application-container Gird Distribution -
          Rows - 2 - space-left: 100vh  
            - Header: 12 vh
            - Page: 88vh
          Columns - 3 
            - 1st Column - 4vw
            - 2nd Column - 90vw
            - 3rd Column - 6vw
        
        
        */
.application-container {
  display: grid;
  grid-template-rows: 8vh repeat(8, 11vh);
  grid-template-columns: 4vw 90vw 6vw;
  gap: 0px;
}

/* 
                  
          HEADER BLOCK - 
          The header styles, contains styles for the logo, nav and the user info section.  
                
        */

/* Sets header element styles wrt to the main block */
.application-container__header {
  grid-row: 1 / 2;
  grid-column: 1 / 4;
}

/* Header Block - sets layout */
.application-header {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  border-bottom: 1px solid rgba(112, 112, 112, 0.2);
}

/* Logo Element styles */
.application-header__logo {
  flex-grow: 1.5;
  display: flex;
  flex-flow: row nowrap;
  align-items: flex-end;
  justify-content: center;
}

/* styles for containing the image */
.application-header__logo img {
  width: 14em;
  height: 2em;
}

/* Header - User section styles */

/* user-section styles wrt to the main container */
.application-header__user {
  flex-grow: 1;
}

/* User section Block Styles - Setting Layout */
.user-section {
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;

  position: relative;
}

/* User section heading element styles */
.user-section h5 {
  font-family: 'Poppins Light';
  color: #707070b0;
  font-size: 0.9em;
  align-self: baseline;
  word-spacing: 1px;
  margin-right: 10px;
  margin-bottom: 25px;
}

/* user section avatar element styles */
.user-section__user-avatar {
  display: flex;
  flex-flow: column nowrap;
  cursor: pointer;
  margin-right: -3px;
}

/* user section avatar image styles */
.user-section__user-avatar img {
  width: 2.1em;
  position: relative;
  margin-left: 10px;
  top: 15px;
}

.application-header__back-to-menu {
  font-size: 0.8em;
  margin-right: 45px;
  margin-bottom: -10px;
  text-align: center;
  display: block;
  color: #4d4d4d9f;
  padding: 5px 0px;
  font-family: 'Poppins SemiBold';
}

.application-header__back-to-menu:hover img {
  transform: translateX(-5px);
}

.application-header__back-to-menu img {
  width: 1em;
  height: 1em;
  padding-right: 7px;
}
