.feature-table__plu-item {
  display: flex;
  flex-flow: column nowrap;
  margin: 5px 0px;
  padding: 0px 10px;
}

.plu-item__heading {
  color: #333;
  font-size: 0.8em;
  letter-spacing: 0.85px;
  text-transform: uppercase;
  font-family: 'Montserrat Regular';
}

.plu-item__plu-list {
  display: flex;
  flex-flow: row wrap;
  align-content: space-around;
  padding: 10px 0px;
}

.plu-item__plu-list span {
  display: inline-block;
  margin: 0px 5px 5px 0px;
  padding: 9px 15px;
  border: 1px solid #7070703d;
  border-radius: 5px;
  color: #333;
  background-color: #8c8c8c13;
  font-size: 0.8em;
  letter-spacing: 0.5px;
  font-family: 'Poppins Light';
  line-height: 1.5em;
}

.plu-item--not-found {
  display: flex;
  flex-flow: row nowrap;
  line-height: 2.5em;
  margin: 10px 25px;
  color: #707070b2;
  font-size: 0.9em;
}

.plu-item--not-found img {
  width: 2em;
  height: 2em;
  margin-right: 10px;
}
