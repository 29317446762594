.API-tos-container {
  max-width: 80vw;
  max-height: 90vh;
  overflow-y: scroll;
  background-color: #fff;
  padding: 20px 0px;
}

.API-tos-container > h1 {
  margin: 20px;
  text-transform: uppercase;
  font-family: 'Montserrat Regular';
  font-size: 1.4em;
  letter-spacing: 1px;
  color: #4d4d4d;
}

.API-tos-container > p > i {
  margin: 20px;
  font-family: 'Poppins Regular';
  letter-spacing: 0.7px;
  font-size: 0.9em;
  color: #707070;
}

.API-tos-container__content {
  padding: 10px 20px;
  color: #707070;
}

.API-tos-container__content > p {
  line-height: 2.5em;
  font-family: 'Poppins Regular';
}

.API-tos-container__content > ol > li {
  font-size: 1em;
  font-family: 'Poppins Regular';
  line-height: 2.5em;
  list-style-position: outside;
}

.API-tos-container__content > ol {
  line-height: 2.5em;
  list-style: upper-roman;
  list-style-position: outside;
  color: #707070;
}

.API-tos-container__content > ol > li {
  line-height: 2.5em;
  list-style: upper-roman;
  list-style-position: outside;
}

.API-tos-container__content > ol > ol li {
  line-height: 2.8em;
  list-style-position: outside;
  list-style: upper-roman;
  color: #707070;
}

.API-tos-container__content a {
  color: #435ec8;
  text-decoration: underline;
}
