.application-container__change-password {
  grid-row: 3 / 9;
  grid-column: 2 / 3;
}

.change-password {
  padding: 3vh 0px 20px 0px;
  border: 1px solid #96939362;
  margin: 0px 25vw;
  margin-top: -5vh;
  border-radius: 9px;
}

.change-password__heading {
  font-size: 2.2em;
  font-family: 'Montserrat Bold';
  text-transform: uppercase;
  color: #707070;
  margin-bottom: 5vh;
  text-align: center;
}

.change-password__change-form {
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
}

.change-password__change-form h5 {
  font-size: 0.9em;
  text-transform: uppercase;
  color: #707070a8;
  margin-bottom: 10px;
  margin-left: 10px;
}

.change-password__change-form div {
  margin-bottom: 3vh;
}

/* sets up the styles for input form element  */
.change-password__change-form input {
  flex-grow: 2;
  color: #707070be;
  padding: 10px 15px;
  border: 1px solid #96939362;
  width: 30vw;
  margin: 5px;
  font-size: 0.9em;
  line-height: 2em;
  border-radius: 7px;
}

/* Sets up the styles when the input boxes are focused - deals with black outline around inputs when typing */
.change-password__change-form input:focus-visible {
  outline-color: transparent;
  outline-width: 0px;
}

.update-password-btn {
  flex-grow: 0;
  align-self: center;
  justify-self: center;
  font-family: 'Poppins Regular';
  padding: 12px 30%;
  margin: 15px 0%;
  line-height: 1.6em;
  border: none;
  /* text-transform: uppercase; */
  color: #fff;
  background-color: #ff9c39;
  border-radius: 7px;
  cursor: pointer;
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: center;
}
