/* OFF MARKET FILTER: DATA TOGGLE STYLES */
.data-toggle {
  width: max-content;
  border-radius: 7px;
  margin-top: 25px;
  display: flex;
  flex-flow: row nowrap;
}

.data-toggle > label {
  margin: 0px 10px;
  color: #707070;
  font-family: 'Poppins Medium';
  font-size: 0.9em;
  cursor: pointer;
}

.data-toggle > label > input {
  margin-right: 7px;
  cursor: pointer;
}

.data-toggle__btn {
  border: none;
  background-color: transparent;
  font-family: 'Poppins Light';
  font-weight: 400;
  font-size: 0.85em;
  padding: 8px 25px;
  cursor: pointer;
  border-top: 1px solid rgba(112, 112, 112, 0.32);
  border-bottom: 1px solid rgba(112, 112, 112, 0.32);
  color: #0058ff;
}

.data-toggle__btn:nth-child(1) {
  border-left: 1px solid rgba(112, 112, 112, 0.32);
  border-top-left-radius: 7px;
  border-bottom-left-radius: 7px;
  color: #0058ff;
}

.data-toggle__btn:nth-child(2) {
  border-top-right-radius: 7px;
  border-bottom-right-radius: 7px;
  border-right: 1px solid rgba(112, 112, 112, 0.32);
  color: #0058ff;
}

.data-toggle__btn--yellow {
  border-top: 1px solid #ff9c39;
  border-bottom: 1px solid #ff9c39;
  border-right: 1px solid #ff9c39 !important;
  background-color: #ff9c39;
  color: #fff !important;
}

.data-toggle__btn--grey {
  background-color: #c0c0c0bd;
  color: #fff !important;
  border-top: 1px solid #3d3a3c1f !important;
  border-bottom: 1px solid #3d3a3c1f !important;
  border-left: 1px solid #3d3a3c1f !important;
}
