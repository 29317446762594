.products {
  display: grid;
  grid-template-columns: repeat(10, 10%);
  grid-template-rows: [header-start] 10vh [header-end] 165vh [footer-start] 60vh [footer-end];
}

.products .header {
  position: relative;
}

.products .header::after {
  content: ' ';
  position: absolute;
  /* background-color: #70707028; */
  width: 100%;
  height: 1px;
  top: 11vh;
}

.products__product-content {
  grid-row: header-end / footer-start;
  grid-column: 1 / 11;
}

.product-content {
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  font-size: 0.9em;
}

.product-content__heading {
  font-family: 'Montserrat Bold';
  font-size: 2em;
  margin-top: 10px;
  color: #707070;
  text-transform: uppercase;
  visibility: hidden;
}

.product-content__sub-heading {
  font-family: 'Poppins Regular';
  font-size: 1.3em;
  font-weight: 400;
  margin-top: 0px;
  color: #707070;
}

.product-content__heading-text {
  font-size: 1.2em;
  color: #707070;
  margin-top: 0px;
  font-family: 'Poppins Regular';
}

.product-cards {
  display: flex;
  flex-flow: row nowrap;
  margin: 30px 0px;
}

.product-cards > div:nth-child(1) {
  margin-right: 5vw;
}

.product-cards__product-item {
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  border: 1px solid #70707027;
  border-radius: 15px;
  padding: 15px 65px 15px 65px;
  box-shadow: -1px 7px 7px 2px rgba(182, 177, 177, 0.438);
  /* background-color: #e6e6e649; */
}

.product-item > img {
  width: 20vw;
  height: 30vh;
}

.product-item__heading {
  font-family: 'Montserrat Bold';
  text-transform: capitalize;
  color: #707070;
  padding: 10px 0px;
  font-size: 1.75em;
}

.product-item__featureList {
  list-style-type: none;
}

.product-item__featureList > li {
  line-height: 3em;
  text-align: left;
  font-size: 0.95em;
}

.product-item__featureList img {
  width: 1em;
  height: 1em;
  margin-right: 15px;
}

.product-item__buy {
  background-color: transparent;
  border: none;
  margin: 20px 0px;
  background-color: #0a66c2;
  padding: 10px 20px;
  border-radius: 7px;
  color: #fff;
}
