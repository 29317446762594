.application-container__application-modal {
  grid-row: 1 / 11;
  grid-column: 1 / 4;
  background-color: #6161606c;
  z-index: 1000000;
  margin-left: -10px;
  margin-top: -10px;

  /* display: none; */
}

.application-container__application-modal--hidden {
  display: none !important;
}

.modal-body {
  position: fixed;
  border: 1px solid rgba(112, 112, 112, 0.4);
  margin: 3vh 28vw;
  background-color: white;
  height: 95vh;
  padding: 5px;
  border-radius: 8px;
}

.modal-body__heading {
  border-bottom: 1px solid rgba(112, 112, 112, 0.2);
  display: flex;
  flex-flow: row nowrap;
  margin: 0px 12px;
}

.modal-body__heading h3 {
  font-family: 'Poppins Light';
  font-size: 1.2em;
  text-transform: uppercase;
  color: hsl(0deg 0% 44% / 69%);
  margin: 5px 0px;
  letter-spacing: 0.8px;
  padding: 13px 0px;
  flex-basis: 80%;
}

.modal-body__heading .modal-heading__img {
  justify-self: right;
  flex-basis: 20%;
  border: none;
  background-color: transparent;
  cursor: pointer;
}

.modal-body__heading .modal-heading__img img {
  width: 0.9em;
  height: 0.9em;
  margin: 0% 0% 0% 75%;
}

.table-wrapper {
  overflow-y: scroll;
  height: 85vh;
}

.table-wrapper::-webkit-scrollbar {
  width: 6px;
  background-color: rgba(223, 223, 223, 0.418);
}

.table-wrapper::-webkit-scrollbar-thumb {
  border-radius: 16px;
  -webkit-box-shadow: inset 0 0 6px rgba(238, 228, 228, 0.2);
  box-shadow: inset 0 0 6px rgba(147, 147, 147, 0.2);
  background-color: rgba(147, 147, 147, 0.7);
  padding: 0px 10px;
}

.table-wrapper::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(158, 154, 154, 0.562);
  border-radius: 16px;
  padding: 5px;
  background-color: rgba(255, 255, 255, 0.85);
}
