@font-face {
  font-family: 'Poppins SemiBold';
  src: url('../src/assets/fonts/popins/Poppins-SemiBold.ttf');
}

@font-face {
  font-family: 'Poppins Regular';
  src: url('../src/assets/fonts/popins/Poppins-Regular.ttf');
}

@font-face {
  font-family: 'Poppins Light';
  src: url('../src/assets/fonts/popins/Poppins-Light.ttf');
}

@font-face {
  font-family: 'Poppins Medium';
  src: url('../src/assets/fonts/popins/Poppins-Medium.ttf');
}

@font-face {
  font-family: 'Montserrat Regular';
  src: url('../src/assets/fonts/Montserrat/Montserrat-Regular.ttf');
}

@font-face {
  font-family: 'Montserrat Bold';
  src: url('../src/assets/fonts/Montserrat/Montserrat-Bold.ttf');
}

@font-face {
  font-family: 'Montserrat Light';
  src: url('../src/assets/fonts/Montserrat/Montserrat-Light.ttf');
}

@font-face {
  font-family: 'Montserrat Medium';
  src: url('../src/assets/fonts/Montserrat/Montserrat-Medium.ttf');
}
