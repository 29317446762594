/*On Market FILTER: FILTER TRAY STYLES */

.filter-btn {
  position: relative;
}

.onMarket__reset-button {
  border: none;
  background: transparent;
  color: #016f99 !important;
  border-radius: 0px;
  padding: 4px 10px;
  font-family: Poppins Light;
  font-size: 0.85em;
  margin-right: 5px;
  cursor: pointer;
}
.onMarket__reset-button:active {
  color: #001720 !important;
}

.onmarket-filter__onmarket-tray {
  display: block;
  width: 30vw;
  position: absolute;
  top: 55px;
  right: 0vw;
  background-color: #fff;
  z-index: 100000;
  color: #4d4d4d;
  border-radius: 9px;
  border: 1px solid rgba(112, 112, 112, 0.315);
  opacity: 1;
}

.onmarket-tray {
  display: flex;
  flex-flow: column nowrap;
  align-items: stretch;
  padding: 10px 15px 20px 15px;
}

.onmarket-filter__data-toggle.data-toggle {
  font-size: 0.95em;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 5px 5px;
  width: 100%;
}

.onmarket-filter__data-toggle > h3 {
  font-family: 'Montserrat Regular';
  text-transform: uppercase;
  color: #4d4d4dd3;
  font-size: 0.85em;
  /* border-bottom: 1px solid #2929291a; */
  padding: 10px 0px;
  margin: 7px 0px 7px 0px;
  width: max-content;
}

.onmarket-filter__data-toggle > div {
  display: flex;
  flex-flow: row nowrap;
  flex-direction: row;
  justify-content: center;
  align-items: flex-start;
  margin: 10px 0px;
}

.onmarket-filter__data-toggle > div > label {
  margin-right: 20px;
  font-family: 'Poppins Medium';
  cursor: pointer;
  font-size: 0.9em;
  color: #8c8c8c;
}

.onmarket-filter__data-toggle > div > label > input {
  margin-right: 7px;
  cursor: pointer;
}

.onmarket-filter__data-toggle > div > label:nth-child(1) {
  margin-left: 5px;
}

.onmarket-filter__data-toggle .data-toggle__btn--red {
  background-color: #ff0000d4;
  border-top: 1px solid #ff0000;
  border-right: 1px solid #ff0000;
  border-bottom: 1px solid #ff0000;
  color: #fff;
}

.onmarket-filter__data-toggle .data-toggle__btn--brown {
  background-color: #8b4c39d1;
  border-top: 1px solid #8b4c3985;
  border-left: 1px solid #8b4c3985;
  border-bottom: 1px solid #8b4c3985;
  color: #fff;
}
