body {
  font-family: 'Poppins Regular', sans-serif;
  font-size: 15px;
  color: #4d4d4d;
}

a {
  color: inherit;
  text-decoration: none;
}

.no-scroll {
  overflow-y: hidden;
}
