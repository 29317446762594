.list-wrapper span {
  display: block;
  font-size: 0.9em;
  letter-spacing: 0.4px;
  color: #707070d3;
  border-radius: 6px;
  padding: 8px 13px;
  line-height: 2.1em;
  margin: 3px 4px 0px 4px;
  z-index: 1000000;
  overscroll-behavior: none;
}

.list-wrapper span:hover {
  background-color: rgba(226, 226, 226, 0.45);
  cursor: pointer;
}

.list-wrapper span.active {
  background-color: rgba(226, 226, 226, 0.55);
  color: #a39494;
}

.list-wrapper span label {
  width: 100%;
  line-height: 2.5em;
  font-size: 1em;
  cursor: pointer;
  z-index: 20000000;
}

.list-wrapper span label input {
  margin-right: 10px;
  z-index: 20000000;
  cursor: pointer;
}
