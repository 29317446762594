.API-data-container {
  width: 100%;
  max-height: 90vh;
  overflow-y: scroll;
  background-color: #fff;
  padding: 20px 0px;
}

.API-data-container > h1 {
  margin: 20px;
  text-transform: uppercase;
  font-family: 'Montserrat Regular';
  font-size: 1.4em;
  letter-spacing: 1px;
  color: #4d4d4d;
}

.API-data-container > div {
  max-width: 90%;
  margin: 0 auto;
}
