/* map controls tray */
.map-container__map-controls {
  grid-row: 1 / 3;
  grid-column: 2 / 3;
  z-index: 10000;
}

.map-controls {
  display: flex;
  flex-direction: row nowrap;
  margin: 15px 0px 10px -5vw;
  background-color: rgb(58, 57, 57);
  width: max-content;
  height: max-content;
  border-radius: 14px;
}

.map-controls__btn {
  background-color: transparent;
  border: none;
  width: max-content;
  height: max-content;
  margin-right: 3px;
  padding: 8px 15px;
  border-right: 1px solid grey;
  cursor: pointer;
  position: relative;
}

.map-controls__btn:hover.layers::after {
  content: 'Layers';
  position: absolute;
  top: -80%;
  left: -10px;
  color: #fff;
  border-radius: 3px;
  font-family: 'Poppins Regular';
  width: 3.2em;
  height: 1em;
  font-size: 0.8em;
  padding: 7px 10px;
  background-color: rgba(58, 57, 57, 0.95);
}

.map-controls__btn:hover.download::after {
  content: 'Download Parcels';
  position: absolute;
  top: -80%;
  left: -40px;
  color: #fff;
  border-radius: 3px;
  font-family: 'Poppins Regular';
  width: 10em;
  height: 1em;
  font-size: 0.8em;
  padding: 7px 10px;
  background-color: rgba(58, 57, 57, 0.95);
}

.map-controls__btn:hover .opacity::after {
  content: 'Opacity';
  position: absolute;
  top: -80%;
  left: -10px;
  color: #fff;
  border-radius: 3px;
  font-family: 'Poppins Regular';
  width: 3.7em;
  height: 1em;
  font-size: 0.8em;
  padding: 7px 10px;
  background-color: rgba(58, 57, 57, 0.95);
}

.map-controls__btn:hover.screenshot::after {
  content: 'Screenshot';
  position: absolute;
  top: -85%;
  left: -10px;
  color: #fff;
  border-radius: 3px;
  font-family: 'Poppins Regular';
  width: 5.5em;
  height: 1em;
  font-size: 0.8em;
  padding: 7px 10px;
  background-color: rgba(58, 57, 57, 0.95);
  border: 1px solid rgba(58, 57, 57, 1);
}

.map-controls__btn:last-child {
  border-right: none;
  padding-right: 35px;
}

.map-controls__btn img {
  width: 1.15em;
  height: 1.15em;
  justify-self: center;
}

div.btn__slider {
  position: absolute;
  top: 100%;
  left: -60px;
  padding: 10px 15px;
  border-radius: 10px;
  display: none;
}

div.btn__slider--active {
  display: block;
}

/* The slider itself */
div.btn__slider .slider {
  -webkit-appearance: none; /* Override default CSS styles */
  appearance: none;
  height: 8px; /* Specified height */
  background: #434242; /* Grey background */
  width: 320%;
  outline: none; /* Remove outline */
  opacity: 0.7; /* Set transparency (for mouse-over effects on hover) */
  -webkit-transition: 0.2s; /* 0.2 seconds transition on hover */
  transition: opacity 0.2s;
  border-radius: 10px;
}

/* Mouse-over effects */
div.btn__slider .slider:hover {
  opacity: 1; /* Fully shown on mouse-over */
}

/* The slider handle (use -webkit- (Chrome, Opera, Safari, Edge) and -moz- (Firefox) to override default look) */
div.btn__slider .slider::-webkit-slider-thumb {
  -webkit-appearance: none; /* Override default look */
  appearance: none;
  width: 15px; /* Set a specific slider handle width */
  height: 15px; /* Slider handle height */
  border-radius: 50%;
  background: black; /* Green background */
  cursor: pointer; /* Cursor on hover */
}

div.btn__slider .slider::-moz-range-thumb {
  width: 25px;
  height: 25px;
  background: #04aa6d; /* Green background */
  cursor: pointer; /* Cursor on hover */
}

.btn__layer-change {
  position: absolute;
  left: 5px;
  top: 120%;
  display: none;
  flex-flow: column nowrap;
  background-color: #343435;
  width: max-content;
  padding: 5px 10px;
  z-index: 1000;
  justify-content: flex-start;
  align-items: flex-start;
  border-radius: 7px;
  font-size: 0.8em;
  letter-spacing: 1px;
}
.btn__layer-change--active {
  display: flex;
}

.btn__layer-change label {
  margin: 5px 0px;
  font-size: 0.86em;
  color: #e7e7e7;
}

.btn__layer-change input {
  margin-right: 10px;
}

/* Edit the Leaflet-measure styles here */
.map-controls .leaflet-bar {
  box-shadow: none;
}

.map-controls .leaflet-bar a {
  background-color: transparent;
}

.map-controls .leaflet-control-measure-interaction {
  background-color: rgba(58, 57, 57, 1);
  font-size: 0.9em;
  position: relative;
  top: -5px;
  left: -14px;
  display: block;
}

.map-controls .leaflet-control-measure-interaction h3 {
  font-size: 1em;
  text-align: left;
  white-space: nowrap;
  padding: 10px 10px;
  font-weight: 400;
  position: relative;
  top: -5px;
}

.map-controls .leaflet-control-measure-interaction a {
  font-size: 0.8em;
  margin-bottom: 8px;
}

.map-controls .leaflet-control-measure-interaction p {
  font-size: 0.8em;
  text-align: left;
}
