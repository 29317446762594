table.zoning-table tbody td {
  padding: 18px 10px;
}

table.zoning-table tbody td:nth-child(1),
table.zoning-table thead th:nth-child(1) {
  width: 10%;
  /* font-weight: 700; */
}

table.zoning-table tbody td:nth-child(2),
table.zoning-table thead th:nth-child(2) {
  width: 20%;
}

table.zoning-table tbody td:nth-child(3),
table.zoning-table thead th:nth-child(3) {
  width: 10%;
}

table.zoning-table tbody td:nth-child(4) {
  width: 20%;
  text-align: start;
}

table.zoning-table thead th:nth-child(4) {
  width: 20%;
}

table.zoning-table tbody td:nth-child(5) {
  width: 40%;
  text-align: start;
}

table.zoning-table thead th:nth-child(5) {
  width: 20%;
}

table.zoning-table tbody tr:hover {
  background-color: #ebebeb59;
  cursor: pointer;
}
