/* Filter Button Styles  */

/* map-menu: filter-btn element styles  */
.filter-btn {
  display: flex;
  flex-flow: row nowrap;
  align-content: flex-start;
  flex-grow: 1.6;
  margin: 7px 5px 2px 5px;
  align-items: stretch;
  justify-items: stretch;
  border: 1px solid rgba(112, 112, 112, 0.32);
  border-radius: 5px;
  background-color: rgba(227, 227, 227, 0.2);
  cursor: pointer !important;
}

/* filter button text  */
.filter-btn__text {
  font-family: 'Poppins Medium';
  font-size: 0.9em;
  flex-grow: 1.2;
  font-weight: 400;
  text-align: center;
  color: rgba(77, 77, 77, 0.85);
  text-transform: uppercase;
  border: none;
  padding: 3px 8px;
  background: transparent;
  cursor: pointer;
}

/* divider between the filter heading and icon */
.filter-btn .filter-btn__divider {
  display: inline-block;
  width: 1px;
  height: 1.5em;
  background-color: rgba(112, 112, 112, 0.7);
  border: none;
  position: relative;
  top: 10px;
}

/* filter button icon container */
.filter-btn__img-container {
  background-color: transparent;
  border: none;
  flex-grow: 1;
  cursor: pointer;
  min-width: max-content;
}

/* filter button icon styles */
.filter-btn button img {
  width: 16px;
  height: 16px;
  margin-top: 2px;
  align-self: stretch;
}

/* filter button active button  */
.map-menu__filter-btn--active {
  background-color: #ff9c39;
  color: #fff !important;
  border: none;
}

.map-menu__filter-btn--active .filter-btn__img-container:hover::after {
  content: 'Filter';
  position: absolute;
  top: -70%;
  left: 73%;
  color: #fff;
  border-radius: 3px;
  font-family: 'Poppins Regular';
  width: 3em;
  height: 1em;
  font-size: 0.8em;
  padding: 5px 10px;
  background-color: #40414394;
}

.map-menu__filter-btn--active button img:hover {
  padding-bottom: 4px;
}
.map-menu__filter-btn--active button {
  color: #fff;
}

.map-menu__filter-btn--active .filter-btn__divider {
  background-color: #fff;
}
