/* User section Block Styles - Setting Layout */
.admin-section {
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  margin-top: 20px;
  position: relative;
}

/* User section heading element styles */
.admin-section h4 {
  font-family: 'Poppins Light';
  color: #707070b0;
  font-size: 0.9em;
  align-self: baseline;
  word-spacing: 1px;
  margin-right: 10px;
  margin-bottom: 25px;
}

/* user section avatar element styles */
.admin-section__avatar {
  cursor: pointer;
  margin-right: 30px;
  border: none;
  background-color: transparent;
}

/* user section avatar image styles */
.admin-section__avatar img {
  width: 2.1em;
  height: 2.3em;
}

.extended-admin-tray {
  position: absolute;
  top: 52px;
  right: 25px;
  background-color: #fff;
  display: flex;
  flex-flow: column nowrap;
  border: 1px solid #4d4d4d1c;
  justify-content: space-between;
  width: 18vw;
  border-radius: 10px;
  padding: 10px 0px 10px 0px;
  box-shadow: -1px 2px 7px 2px rgba(180, 177, 177, 0.363);
  z-index: 300000;
}

.extended-admin-tray--hidden {
  display: none;
}

.admin-application-header__back-to-menu {
  font-size: 1em;
  margin-left: 20px;
  display: block;
  color: #4d4d4d9f;
  padding: 5px 0px;
  font-family: 'Poppins Regular';
  line-height: 2em;
  color: #ff0000;
}

.admin-application-header__back-to-menu img {
  width: 1.2em;
  height: 1.3em;
  padding-right: 7px;
  margin-bottom: -2px;
}
