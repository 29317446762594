.request-details {
  display: flex;
  flex-flow: column nowrap;
  margin: 40px 0px;
}

.request-details__heading {
  font-family: 'Poppins Regular';
  letter-spacing: 0.14px;
  position: relative;
  left: 2px;
  width: max-content;
  color: #8c8c8c;
  font-size: 0.8em;
  padding: 7px 15px;
  margin-bottom: 3px;
  background-color: #fff;
  border-radius: 3px;
  border: 1px solid #8c8c8c1e;
}

.request-details__param-table {
  padding: 10px 10px;
  margin: 0px 5% 0px 0.2%;
  background-color: #fff;
  border-radius: 6px;
  border: 1px solid #8c8c8c1e;
}

.request-details__param-table td {
  padding: 20px 50px;
  font-size: 0.85em;
  background-color: #fff;
  border-bottom: 1px solid #8c8c8c38;
}

.request-details__param-table td:nth-child(1) {
  font-family: 'Poppins SemiBold';
  color: #01162793;
  width: 10%;
}

.request-details__param-table td:nth-child(2) {
  width: 20%;
  font-family: 'Poppins SemiBold';
  color: #7070707c;
  text-transform: uppercase;
}

.request-details__param-table td:nth-child(3) {
  width: 60%;
  color: #707070ab;
  padding: 0px 10px;
}

.request-details__param-table tr:last-child td {
  border-bottom: none;
}
