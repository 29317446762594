/* List wrapper styles specifically for the map-menu dropdowns */
.map-menu__list-wrapper {
  box-sizing: border-box;
  border: 1px solid rgba(112, 112, 112, 0.32) !important;
  flex-basis: 100% !important;
  border-top: none !important;
  text-align: left !important;
  font-size: 1.3em;
  border: none !important;
  max-height: 37vh !important;
  z-index: 10000 !important;
  scroll-behavior: smooth;
  position: unset !important;
  margin: 1px 0px !important;
  padding: 5px 0px !important;
}

.filter-control__list-wrapper {
  top: -25px;
  min-width: 101% !important;
}

.list-wrapper {
  position: absolute;
  z-index: 10000;
  max-height: 20vh;
  border: 1px solid rgba(112, 112, 112, 0.32);
  padding: 6px;
  transform: translateZ(200px);
  background-color: #fff;
  opacity: 1;
  box-shadow: -1px 14px 7px 2px rgba(235, 233, 233, 0.781);
  display: none;
  min-height: fit-content;
}

.list-wrapper--active {
  display: flex;
}

/* Contains the list items - Filter Values  */
.list-items {
  overflow-y: scroll;
  background-color: #fff;
  height: 17vh;
  width: 100%;
  flex-basis: 100%;
}

.list-items::-webkit-scrollbar {
  width: 6px;
  background-color: rgba(223, 223, 223, 0.418);
}

.list-items::-webkit-scrollbar-thumb {
  border-radius: 16px;
  -webkit-box-shadow: inset 0 0 6px rgba(238, 228, 228, 0.2);
  box-shadow: inset 0 0 6px rgba(147, 147, 147, 0.2);
  background-color: rgba(147, 147, 147, 0.7);
}

.list-items::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(219, 213, 213, 0.623);
  border-radius: 16px;
  padding: 5px;
  background-color: rgba(255, 255, 255, 0.85);
}

.list-items span.list-items__item {
  display: flex;
  flex-flow: row nowrap;
  /* line-height: 1em; */
}

.map-menu .list-items span.list-items__item > span {
  padding: 5px 15px;
  overflow-wrap: anywhere;
}

.map-menu .list-items span.list-items__item span:nth-child(1) {
  flex-basis: 10%;
  display: inline-block;
  padding: 0px 0px;
  margin: 0px 0px 0px 3px;
}

.map-menu .list-items span.list-items__item span:nth-child(1):hover {
  background-color: transparent;
}

.map-menu .list-items span.list-items__item span:nth-child(1) img {
  width: 1.4em !important;
  height: 1.3em !important;
  max-width: 1.4em !important;
  margin-right: 0px;
  margin-left: -10px;
  padding-right: 0px;
}

.map-menu .list-items span.list-items__item span:nth-child(2) {
  flex-basis: 90%;
  flex-grow: 1;
  margin: 0px 0px 0px -7px;
  font-size: 0.9em;
  line-height: 1.8em;
}

.map-menu .list-items span.list-items__item span:nth-child(2):hover {
  background-color: transparent !important;
}
