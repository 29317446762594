/* LANDING PAGE FOOTER */

.main-grid__footer {
  background-color: #4d4d4d;
}

.footer-component {
  grid-row: footer-start / footer-end;
  grid-column: 1 / 11;
  display: grid;
  grid-template-columns: 14.2% 14.2% 14.2% 14.2% 14.2% 14.2% 14.2%;
  grid-template-rows: 14.2% 14.2% 14.2% 14.2% 14.2% 14.2% 15%;
  color: white;
  height: 100%;
}

.footer-component__copyright-social {
  grid-row: 3 / 5;
  grid-column: 1 / 3;
}

.copyright-social {
  display: flex;
  flex-flow: column nowrap;
}

.copyright {
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  justify-items: center;
}

.copyright__img {
  width: 10em;
  padding-left: 65px;
}

.copyright__heading {
  font-size: 1.5em;
  margin-top: 5px;
  font-family: 'Montserrat Bold';
}

.copyright-social__social {
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
}

.copyright-social__social img {
  width: 1.5em;
  margin-right: 20px;
}

.footer-component__account {
  grid-row: 1 / 5;
  grid-column: 4 / 5;
}

.link-column {
  display: flex;
  flex-flow: column nowrap;
  justify-content: baseline;
  margin-top: 50px;
  margin-right: 25px;
}

.link-column__heading {
  font-family: 'Montserrat Regular';
  text-transform: capitalize;
  font-size: 1em;
  letter-spacing: 0.7px;
  color: #ff9c39;
  border-bottom: 2px solid #707070da;
  width: max-content;
  padding-bottom: 5px;
  margin-bottom: 30px;
}

.link-column__link {
  margin-bottom: 20px;
  font-family: 'Poppins Regular', sans-serif;
  text-transform: capitalize;
  font-size: 0.9em;
}

.link-column__link:hover {
  color: #ff9c39;
}
.footer-component__products {
  grid-row: 1 / 5;
  grid-column: 5 / 6;
}

.footer-component__webinars {
  grid-row: 1 / 5;
  grid-column: 6 / 8;
}

.footer-component__misc-links {
  grid-row: 7 / 8;
  grid-column: 1 / 8;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  border-top: 3px solid #707070;
  padding-top: 10px;
}

.misc-link-row__link {
  margin-right: 30px;
  font-family: 'Montserrat Bold', sans-serif;
  font-size: 0.9em;
  color: #03a9f4;
  text-transform: uppercase;
  border-right: 2px solid #707070;
  padding-right: 20px;
}
