/* Filter Dropdown Button Styles */
.filter-control__dropdown-btn {
  display: flex;
  flex-basis: 100%;
  flex-flow: row nowrap;
  align-self: stretch;
  justify-self: baseline;
  border-radius: 5px;
  padding: 1px 15px;
  background-color: transparent;
  cursor: pointer;
  border: 1px solid rgba(112, 112, 112, 0.32);
  color: rgba(77, 77, 77, 0.85);
  font-size: 0.75em;
}

.filter-control__dropdown-btn:focus-visible {
  outline: none;
  outline-width: 0px;
}
.filter-control__dropdown-btn:hover {
  background-color: rgba(227, 227, 227, 0.2);
}

.offmarket-filter__filter-menu .filter-control img {
  width: 1.1em;
  height: 0.8em !important;
}
