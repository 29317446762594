.user-input-form {
  display: flex;
  flex-flow: column;
  justify-content: flex-start;
  width: 100%;
  padding: 0px 20px;
}

.user-input-form input:focus-visible {
  outline-color: transparent;
  outline-width: 0px;
}

.user-input-form input {
  font-size: 0.85em;
  color: #4d4d4d !important;
}

.account-info > div {
  padding: 0px 0px !important;
  margin: 35px 10px !important;
  width: 90%;
}

.userDetails {
  display: flex;
  flex-flow: row wrap;
  margin: 0px 5px;
  width: 98%;
}

.userDetails > div {
  flex-basis: 40%;
}

.userDetails > label {
  display: block;
  flex-basis: 48%;
  margin: 50px 5px 30px 5px;
  color: #8c8c8c;
  cursor: pointer;
}

.userDetails > label > input {
  margin-right: 10px;
}

.userDetails > div:last-child {
  flex-basis: 100%;
  margin-top: 20px;
  padding-bottom: 20px;
}

.user-form-btn {
  flex-grow: 0;
  font-family: 'Montserrat Medium';
  padding: 12px 30%;
  margin: 0px 10px 20px 10px;
  border: none;
  color: #fff;
  background-color: #ff9c39;
  border-radius: 7px;
  cursor: pointer;
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: center;
  width: 90%;
}

.account-info__heading {
  font-size: 0.8em;
  width: 100%;
  box-sizing: border-box;
  display: flex;
  text-transform: uppercase;
  color: #4d4d4dd3;
  font-family: 'Montserrat Regular';
  margin: 20px 10px 10px 10px;
  background-color: #fff;
  flex-basis: 47%;
  padding-right: 20px;
  position: relative;
  z-index: 1;
}

.account-info__heading::after {
  content: ' ';
  position: relative;
  width: 60%;
  margin: 0px auto;
  height: 1px;
  background-color: #4d4d4d56;
  top: 7px;
  left: 0%;
  z-index: -1;
}

.user-form-err {
  color: #ff0000 !important;
}
