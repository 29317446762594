/* dropdown button Styles */

/* map-menu: drop down button styles specific to map menu  */

.map-menu__dropdown-btn {
  padding: 0px 15px;
  margin: 8px 10px 0px 0px !important;
  flex-grow: 1.5;
  font-size: 0.7em;
  flex-basis: 11vw;
  color: #1a1717 !important;
  z-index: 100000;
  display: flex;
  flex-flow: row wrap;
  position: relative !important;
}

.map-menu__dropdown-btn--active {
  z-index: 10000;
  background-color: #fff;
  padding: 0px 5px !important;
  height: fit-content;
  border: 1px solid #adb1db69;
  box-shadow: -1px 0px 7px 7px rgba(180, 180, 180, 0.096);
}

.map-menu__dropdown-btn--active .list-wrapper {
  box-shadow: none !important;
}

.map-menu__dropdown-btn--active .list-wrapper__list-items {
  height: 18.5vh;
}
.map-menu__dropdown-btn--active .dropdown-btn__text {
  flex-basis: 60%;
  margin: 15px 6%;
  color: #707070;
}

.map-menu__dropdown-btn--active span.dropdown-btn__btn {
  display: inline-block;
  flex-basis: 18%;
}

.map-menu__dropdown-btn--active::after {
  content: '';
  position: absolute;
  width: 85%;
  top: 23%;
  z-index: 1000;
  height: 1px;
  background-color: rgba(112, 112, 112, 0.22);
}

.map-menu__dropdown-btn:focus-visible {
  outline: none;
  outline-width: 0px;
}

.dropdown-btn--disabled {
  cursor: not-allowed;
  opacity: 0.7 !important;
}
