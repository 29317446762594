.dashboard-search__search-form {
  grid-row: 5 / 6;
  grid-column: 2 / 3;
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: center;
  margin-top: 10px;
  position: relative;
}

.search-form__input {
  flex-grow: 5;
  padding: 20px 30px;
  font-size: 0.9em;
  border: none;
  opacity: 1;
  border-radius: 18px 0% 0% 18px;
  color: #4d4d4da2;
  /* text-transform: uppercase; */
  background-color: #fff;
}

.search-form__input:focus-visible {
  outline-color: transparent;
  outline-width: 0px;
}

.search-form__input--tray-active {
  border-radius: 18px 0% 0% 0px !important;
  /* text-transform: uppercase; */
}
.search-from__submit-btn {
  flex-grow: 1;
  background-color: #f8f8f8;
  border: none;
  padding: 15.5px 0px;
  margin: 5px 0px;
  color: #4d4d4d;
  border-radius: 0% 18px 18px 0%;
  border-left: 1px solid rgba(112, 112, 112, 0.3);
  font-size: 1.4em;
  font-weight: 700;
  letter-spacing: 0.8px;
  cursor: pointer;
}

.submit-btn--active {
  background-color: #ff8f1fec;
  color: #fff;
  font-family: 'Poppins Light';
  opacity: 1;
  z-index: 100;
  box-shadow: 10px 10px 10px 10px rgba(255, 255, 255, 0.116);
}

.dashboard-search__btns {
  grid-row: 6 / 7;
  grid-column: 2 / 3;
  display: flex;
  flex-flow: row nowrap;
  margin-top: 20px;
  justify-content: center;
}

.btns-row__btn {
  background-color: #4d4d4d;
  border: none;
  color: #fff;
  margin-right: 10px;
  font-size: 1.2em;
  padding: 5px 60px;
  border-radius: 10px;
}
