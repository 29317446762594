/* map-menu styles  */
.map-page__map-menu {
  grid-row: 1 / 2;
  grid-column: 1 / 11;
}

/* map-menu block element - layout */
.map-menu {
  display: flex;
  flex-flow: row nowrap;
  margin: 4.15vh 5px 4.15vh 5px;
  position: relative;
}
