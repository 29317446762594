.search-form__suggestions {
  position: absolute;
  list-style-type: none;
  background-color: #fff;
  top: 95%;
  min-width: 79.7%;
  max-width: 79.7%;
  left: 0px;
  border-radius: 0px 0px 14px 14px;
  display: none;
  opacity: 1;
}

.dashboard-search__g-icon {
  display: flex;
  flex-flow: row nowrap;
  margin: 10px 5px;
  border-top: 1px solid #4d4d4d1e;
}

.dashboard-search__g-icon img {
  justify-self: center;
  display: block;
  width: 8.5em;
  height: 1em;
  margin-left: 8%;
  margin-top: 10px;
  padding: 3px 0px;
  margin-left: 77%;
}
